@import "../../../styles/sizes";

.container_user {
    max-width: 1180px;
    padding-top: 0;
    h2 {
        margin-bottom: 20px; } }

.lk {
    &-list {
        display: flex;
        max-width: 1180px;
        justify-content: center;
        &__item {
            p {
                white-space: nowrap; } } } }

.lk-list__content {
    position: relative;
    background-color: white;
    width: 100%; }

.form_addresses {
    display: flex; }

@media screen and (min-width: $mobileWidth + 1) {
    .lk {
        &-list {
            &__item {
                padding: 15px 20px;
                font-weight: 500;
                font-size: 18px;
                color: #1D3A65;
                margin-bottom: -20px;
                padding-bottom: 25px;
                display: flex;
                align-items: center;
                box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.15);
                border-radius: 25px 25px 0px 0px;
                background-color: white;
                transition: all ease 0.2s;
                cursor: pointer;
                img {
                    margin-right: 10px; } } } }

    .lk-list__item.active {
        z-index: 6 !important;
        box-shadow: 0px -14px 13px rgba(0, 0, 0, 0.05); }

    .lk-list__content {
        z-index: 5;
        margin: 0 auto;
        max-width: 1078px;
        padding: 20px;
        box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.15);
        height: auto;
        border-radius: 25px; } }


@media screen and (max-width: $mobileWidth) {
    .container_user {
        width: 100%;
        h2 {
            margin-bottom: 13px;
            font-size: 22px;
            text-align: start; } }

    .lk {
        &-list {
            justify-content: flex-start;
            width: 100%;
            margin-bottom: 30px;
            .slick-slide {
                width: 240px !important;
                text-align: center; }
            &__item {
                padding: 4px 10px;
                white-space: nowrap;
                font-weight: 500;
                background: none;
                box-shadow: none;
                &:not(:last-of-type) {
                    margin-right: 20px; }
                font-size: 18px;
                color: #1D3A65;
                opacity: 0.6;
                transition: all ease 0.2s;
                cursor: pointer; } } }

    .lk-list__item.active {
        border-bottom: 1px solid #1D3A65;
        opacity: 1;
        box-shadow: none; }

    .lk-list__content {
        margin-bottom: 60px; } }
