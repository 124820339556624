$colorRed: #EF6752;

.menu {
    display: flex;
    justify-content: space-between;

    &__link {
        color: inherit;
        display: block;
        height: 100%;
        &_header:hover {
            color: $colorRed; }

        &:hover {
            cursor: pointer; }
        &_pink:hover {
             color: $colorRed; }
        &_shadow:hover {
            padding-left: 5px;
            margin-left: -5px;
            vertical-align: middle;
            border-radius: 15px;
            box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25); } }

    &_header {
        width: 100%;
        max-width: 1080px;
        margin: 0 auto;
        font-weight: 500;
        position: relative;
        a:hover {
            text-decoration-line: none; } }


    &_footer {
        font-style: normal;
        text-transform: uppercase;
        flex-flow: column;
        a {
            line-height: 1.4em; } }

    &__item_buyers {
        position: relative; }

    &__item_hidden:hover {
            cursor: pointer;
            color: #EF6752; }

    &_buyers, &_block {
        position: absolute;
        left: 0;
        z-index: 101;
        overflow: hidden;
        transition: all ease 0.5s;
        background-color: white; }

    &_buyers {
        height: 0; }

    &_buyers {
        margin-top: 10px;
        min-width: 280px;
        width: auto;
        flex-flow: column;
        padding-left: 10px;
        padding-right: 20px;
        transform: translateX(-20px);
        box-shadow: 0px 15px 13px rgba(0, 0, 0, 0.25);
        border-radius: 0px 0px 25px 25px;
        font-size: 0.8em;
        line-height: 1.1em;
        font-weight: normal;

        li {
            height: 100%;
            vertical-align: center;
            padding-left: 10px;
            &:not(:last-child) {
                border-bottom: 1px solid #EF6752; } }

        a {
            vertical-align: middle;
            line-height: 42.5px;
            &:hover {
                text-decoration-line: none;
                color: $colorRed; } } }

    &_catalog {
        flex-flow: column; }

    &_block {
        width: 100%;
        display: flex;
        justify-content: space-between;
        box-shadow: 0px 16px 13px rgba(0, 0, 0, 0.25);
        border-radius: 0px 0px 25px 25px;
        &__wrapper {
            display: block;
            width: 1040px;
            margin: 0 auto;
            height: auto;
            column-count: 4;

 }            // justify-content: space-between

        &__item {
            display: flex;
            flex-flow: column;
            max-width: 200px;
            width: 100%;
            padding: 0 5px;
            margin-bottom: 30px;
            break-inside: avoid-column;
            break-inside: avoid;

            @-moz-document url-prefix() {
                display: inline-block; }

            h4 {
                font-size: 0.9em;
                color: #2F323A;
                text-decoration-line: underline;
                font-weight: 500;
                margin-bottom: 15px; }

            a {
                color: inherit;
                text-decoration-line: none;
                font-size: 15px;
                line-height: 15px;
                letter-spacing: -0.2px;
                font-weight: normal;
                transition: all ease 0.4s; }

            a:hover {
                color: #EF6752; } } } }


.menu__item_buyers:hover .menu_buyers {
    padding-top: 10px;
    height: 180px;
    margin-top: 0px; }

.menu__item_buyers:hover .menu__item_hidden {
    color: #EF6752; }


.menu__item_hidden {
    color: #2F323A; }

.menu__item_catalog:hover .menu__item_hidden {
    color: #EF6752; }

.menu__link_catalog {
    font-size: 15px !important;
    line-height: 18px !important;
    padding: 3px 0; }
.menu-wrapper {
    position: relative; }

.menu-name:hover .menu-fish {
    z-index: 1;
    height: 390px; }

.menu-name:hover .menu-name__title {
    cursor: pointer;
    z-index: 100;
    background-color: white;
    vertical-align: middle;
    border-radius: 15px 0 0 15px;
    padding-left: 5px;
    margin-left: -5px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25); }

.menu-fish {
    transition: all ease 0.2s;
    height: 0;
    overflow: hidden;
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(100%, -10px);
    background-color: white;
    min-width: 288px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    border-radius: 15px; }

.menu-name__title {
    color: #2F323A;
    font-weight: normal;
    font-size: 0.75em; }

.menu_fish {
    flex-flow: column;
    padding: 10px;
    .menu__item a:hover {
        padding-left: 0;
        box-shadow: none;
        border-radius: 0;
        color: $colorRed;
        margin-left: 0; } }








