@import "../../../styles/sizes";

.map {
    width: 100%;
    height: 430px; }

.map-link-yandex {
    position: absolute;
    z-index: 1000;
    border: none;
    border-radius: 25px;
    background-color: #EF6752;
    color: white;
    padding: 5px 15px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
    font-size: 14px;
    bottom: 10px;
    left: 50%;
    transform: translateX(-30%);
    cursor: pointer;
    &:hover {
        background-color: white;
        border: 1px solid #EF6752;
        padding: 4px 14px;
        color: #EF6752;
        box-shadow: none;
        text-decoration-line: none; } }

.balloon-header {
    font-size: 0.923075em;
    max-width: 262px;
    margin-bottom: 5px; }

.balloon-description {
    color: #878A95;
    span {
        color: #93C69B;
        font-weight: bold; } }

.balloon-link {
    color: #000000;
    font-size: 0.923075em;
    font-weight: 500;
    line-height: 1.1em; }

.ymaps-2-1-77-balloon__layout {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
    border-radius: 11px;
    border: none; }

.ymaps-2-1-77-balloon__content {
    > ymaps {
        padding-left: 5px; } }
.ymaps-2-1-77-map ymaps {
    border-radius: 8px; }

.placemark {
    width: 1000px; }

.shops-remains {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1000;
    background: white;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
        text-align: center; } }

.zoom-buttons {
    position: absolute;
    top: 150px;
    right: 28px;
    img {
        cursor: pointer;
        display: block;
        margin-top: 20px; } }

@media screen and (min-width: $mobileWidth + 1) {
    .container_map {
        position: relative;
        max-width: 1100px;
        margin: 0 auto;
        min-height: 430px;
        padding: 0;
        margin-bottom: 60px; }

    .map {
        max-width: 1100px;
        margin: 0 auto; }

    .filter-map {
        position: absolute;
        top: 15px;
        left: 15px; } }

@media screen and (max-width: $mobileWidth) {

    .container_map {
        position: relative;
        width: 100%;
        margin-bottom: 30px;
        padding: 0 15px; }

    .map {
        height: 250px;
        margin-bottom: 15px; }

    .map-link-yandex {
        font-size: 14px;
        letter-spacing: -0.430px;
        padding: 6px;
        transform: translateX(0);
        left: 5px;
        bottom: 5px; } }
