@import "../../styles/sizes";

.section_contacts {
    margin: 0 auto;
    h2 {
        margin-bottom: 21px; } }

.container_contacts {
    max-width: 1180px;
    padding-top: 0; }

.contacts__desc-wrapper {
    height: 100%;
    border-radius: 26px;
    background: url(../../assets/contactsBG.svg) center no-repeat; }


.contacts {
    display: flex;
    align-items: flex-start;

    &__desc {
        padding: 39px 28px;
        max-width: 323px;
        display: flex;
        flex-flow: column;
        height: 100%;
        justify-content: space-between;
        align-items: flex-start;
        color: #283343;
        line-height: 1.2em;
        font-weight: normal; }

    &-block {
        &__title {
            font-size: 1em;
            font-weight: bold;
            margin-bottom: 6px; } }

    &-block:not(:last-child) {
        margin-bottom: 30px; }

    &-block__desc {
        color: inherit; }

    &-block__desc_link:hover {
        text-decoration-line: none;
        color: #EF6752; }

    &-block__desc_social {
        a:hover svg path {
            fill: #EF6752; }

        a:not(:last-child) {
            margin-right: 10px; } }


    &__form {
        max-width: 627px;
        h2 {
            margin-bottom: 14px; } } }

.form_contacts {
    padding: 38px 50px 38px 33px;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    box-shadow: none;
    height: auto;
    position: relative;
    transform: none;
    padding: 0;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;


    .form-field {
        height: 40px;
        margin-bottom: 20px;
        border: 2px solid #EF6752;
        border-radius: 26px;

        &_input {
            flex-basis: 47.8%;
            padding-left: 15px;
            padding-right: 15px; }
        &_textarea {
            flex-basis: 100%;
            height: 183px;
            padding-top: 11px;
            padding-left: 18px;
            resize: none; }
        &_btn {
            max-width: 179px;
            flex-basis: 34%;
            margin-left: auto;
            color: #fff;
            border: none;
            font-weight: bold;
            text-transform: uppercase; }
        &_select {
            background: white;
            &:required:invalid {}
            color: gray;
            option[value="Тип обращения"] {
                color: grey;
                display: none; } }

        &_type {
            padding: 0;
            p {
                margin-left: 15px;
                line-height: 36px;
                color: #3D3D3D;
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-right: 10px; }
            svg {
                transition: transform ease .2s; }
            svg.active {
                transform: rotate(180deg); } } } }

.menu_contactsType {
    padding: 0 10px;
    transform: translateY(-2px);
    width: 270px;
    margin: 0 auto;
    background-color: #fff;
    height: 0;
    overflow: hidden;
    box-shadow: 0px 15px 13px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 25px 25px; }
.menu_contactsType.active {
    position: relative;
    height: 200px;
    z-index: 200;
    li {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.408px;
        height: 40px;
        margin-left: 13px;
        margin-right: 19px;
        cursor: pointer;
        line-height: 40px;
        p {
            margin-left: 8px; } }
    li:hover p {
        color: #EF6752; }
    li:not(:last-child) {
        border-bottom: 1px solid #EF6752; } }


.successModal {
    background: #fff;
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: center;
    flex-direction: column;
    &__message {
        color: #EF6752;
        font-size: 2.5em;
        text-align: center;
        line-height: 40px;
        font-weight: 600;
        margin-bottom: 30px; }
    &__button {
        background: #EF6752;
        height: 40px;
        width: 50%;
        border: none;
        color: #fff;
        border-radius: 10px; } }

.noActiveBtn {
    background-color: #C4C4C4 !important; }

@media screen and (min-width: $mobileWidth + 1) {
    .contacts {
        padding: 38px 50px 38px 33px;
        height: 570px;
        box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.25);
        border-radius: 35px;
        justify-content: space-between; } }

@media screen and (max-width: $mobileWidth) {
    .contacts__desc-wrapper {
        background-image: url(../../assets/contactsBG.svg);
        background-size: cover;
        background-repeat: no-repeat; }

    .menu_contactsType {
        width: 100%;
        top: 0 !important;
        .menu__item {
            padding: 0px; } }

    .section_contacts {
        h2 {
            margin-bottom: 13px;
            font-size: 22px; } }

    .contacts {
        flex-direction: column;

        &__desc {
            padding: 35px 28px;
            max-width: inherit;
            &-wrapper {
                width: 100%; } }

        &__form {
            h2 {
                margin-bottom: 14px;
                margin-top: 40px; } } }

    .form_contacts {

        .form-field {
            &_input {
                flex-basis: auto;
                width: 100%; }
            &_btn {
                flex-basis: auto;
                margin-bottom: 47px; } } }

    .requisite {
        margin-bottom: 45px;
        color: #283343; }
    &__title {
        font-size: 16px;
        margin-bottom: 6px; }
    &__desc {
        line-height: 18px; } }
