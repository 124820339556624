@import "../../styles/sizes";

.container_job {
    max-width: 1180px;
    padding-top: 0; }

.job-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }

@media screen and (max-width: $mobileWidth) {

    .job-content {
        flex-direction: column;
        .job-title {
            font-size: 24px;
            margin-bottom: 13px; } } }
