@import "../../styles/sizes";

.categories {
    padding: 25px 20px;
    max-width: 1100px;
    width: 100%;
    height: 300px;
    margin: 0 auto;
    background: url(../../assets/categoriesBG.svg) center center no-repeat;
    background-size: cover;

    &-title {
        margin-bottom: 35px; }

    &-cards {
        display: flex;
        align-items: center;
        justify-content: space-between; }

    &__card {
        display: flex;
        flex-flow: column;
        align-items: center;
        height: 155px;
        justify-content: space-between; }

    &__img {
        transition: all ease 0.2s;

        &:hover {
            transition: all ease 0.4s;
            transform: scale(1.2); } }

    &__category_transform {
        transform: translateY(50%); } }

@media screen and (max-width: $mobileWidth) {
    .categories {
        padding: 15px 20px 30px;
        margin: auto;
        margin-bottom: 35px;
        height: auto;

        &-title {
            font-size: 22px; }

        &-cards {
            flex-wrap: wrap; }

        &__card {
            width: 135px;
            margin-bottom: 35px;

            h4 {
                font-size: 18px !important; }

            transition: none;

            &__img {
                transition: all ease 0.2s; } } } }
