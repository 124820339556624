@import "../../../styles/sizes";

.recepies-cards {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    .card {
        &__img {
            width: 254px;
            height: 176px;
            object-fit: cover;
            background: #C4C4C4; } } }

@media screen and (min-width: $mobileWidth + 1) {
    .recepies-cards {
        .card {
            flex-basis: 25%;
            padding: 10px;
            box-sizing: border-box;
            &-top__img {
                object-fit: cover; }
            &:hover {
                padding: 7px; } } }

    .recepies-filter {
        .card {
            max-width: 254px;
            box-sizing: content-box; } } }


@media screen and (max-width: $mobileWidth) {
    .recepies-cards {
        a {
            width: 100%;
            display: block; }
        .card {
            width: 100% !important;
            font-size: 14px;
            padding: 0;
            &__title {
                font-size: 16px;
                margin-top: 0;
                margin-bottom: 5px; }
            &__description {
                margin-bottom: 30px; }

            &__img {
                width: 100%;
                height: 250px;
                object-fit: cover;
                background: #C4C4C4;
                margin-bottom: 15px; } } } }

