@import "../../../styles/sizes";

.review-stars {
    width: 324px;

    &__mark {
        background: url(../../../assets/reviewsBG.svg) center no-repeat;
        background-size: cover;
        border-radius: 24px;
        height: 110px;

        &-content {
            padding: 20px 35px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            svg {
                width: 23px !important;
                margin-right: 5px; } } }

    &__average {
        font-size: 3.5em;
        font-weight: bold; }

    > * {
        margin-bottom: 20px; }
    &__list {
        width: 100%; }

    &__item {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        > *:not(:last-child) {
            margin-right: 12px; } }

    &__measure {
        background-color: #F6F8FB;
        border-radius: 18px;
        display: block;
        height: 10px;
        flex-grow: 2;
        span {
            background-color: #8DD76E;
            border-radius: 18px;
            height: 10px;
            display: block; } }

    &__btn {
        border: none;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
        width: 100%; }

    svg {
        width: 16px; } }


@media screen and (max-width: $mobileWidth) {

    .review-stars {
        width: 100%;

        &__marks {
            h4 {
                margin-bottom: 13px;
                font-size: 24px; } }

        &__item {

            font-size: 12px; } } }
