@import "../../../../styles/sizes";

.lk-addresses__add-form h4 {
    font-size: 1.5em;
    margin-bottom: 15px; }

.form_addresses {
    box-shadow: none;
    flex-flow: row;
    width: 100%;
    padding: 0;
    height: auto;
    &__inputs-small {
        display: flex;
        justify-content: space-between; }
    .form__field {
        &_input {
            height: 40px;
            margin-bottom: 20px;
            &_small {
                width: 30%; } }
        &_textarea {
            height: 180px;
            padding: 10px 18px;
            margin-bottom: 10px; }
        &_checkbox {
            display: flex;
            text-align: center;
            input {
                margin-right: 8px;
                transform: translateY(20%); } } }

    .react-dadata__input {
        height: 40px;
        margin-bottom: 20px;
        border: 2px solid #EF6752;
        border-radius: 26px; }

    &__block {
        display: flex;
        flex-flow: column;
        max-width: 500px;
        flex-basis: 100%;
        &_left {}

        &_right {} }

    &__btns {
        margin-top: 65px;
        margin-left: auto;
        button:first-child {
            margin-right: 15px;
            background: #878A95; } } }

.lk-addresses__show {
    margin-top: 25px; }

.lk-addresses__show__item {
    position: relative;
    h4 {
        margin-bottom: 20px; }
    .item-block {
        h5 {
            width: 180px;
            font-size: 1em;
            font-weight: bold; }
        &__field {
            display: flex;
            margin-bottom: 10px; } }


    .item-icons {
        position: absolute;
        top: 0;
        right: 0;
        img {
            cursor: pointer; }
        img:not(:last-child) {
            margin-right: 20px; } }


    &:not(:last-child) {
        margin-bottom: 25px;
        .item-block {
            border-bottom: 1px solid #EF6752; } } }

.address-validate {
    text-align: center;
    color: red !important;
    font-size: 18px !important; }

@media screen and (min-width: $mobileWidth) {
    .form_addresses {
        position: static;
        transform: none;
        top: 0;
        left: 0; } }
@media screen and (max-width: $mobileWidth) {

    .lk-addresses__add-form h4 {
        font-size: 18px;
        margin-top: 15px; }

    .form_addresses {
        flex-direction: column;

        &__btns {
            margin-top: 30px;
            display: flex;
            margin-left: 0; } }

    .lk-addresses__show {
        h4 {
            font-size: 20px;
            margin-bottom: 16px; } }

    .lk-addresses__show__item {
        .item-block__field {
            flex-direction: column; }

        .item-icons {
            transform: translateY(-175%); } }

    .sup_label {
        margin-bottom: 13px;
        sup {
            color: red; } } }
