@import "../../../styles/sizes";

@media screen and (max-width: $mobileWidth) {
  .card-bottom-slider {
    flex-direction: column;
    .card-bottom__costs {
      display: flex;
      align-items: center;
      span {
        margin-left: 5px; } }
    .card-bottom__price {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 8px;
      .card-bottom__weight {
        margin-left: 5px; }
      .card-bottom__costs_throw, .card-bottom__weight {
        font-size: 14px; } }
    .card-bottom__icons {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      .cart-item__counter {
        flex-grow: 0;
        width: auto; }
      img {
        margin: 0; }
      .card-bottom__icon:first-of-type {
        margin-right: 8px; } } }
  .block-slider {
    .card_main_recommended {
      flex-direction: column !important; }

    .card {
      width: 100%;
      margin-bottom: 40px;
      h4 {
        font-size: 16px; }
      a {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between; }
      &-images {
        max-width: 140px;
        margin-right: 15px;
        position: relative;
        &__img {
          width: 120px;
          height: 100px;
          object-fit: contain;
          background-color: white; }
        &__icon {
          width: 30px;
          height: 30px; } }
      &__title {
        margin-top: 0;
        margin-bottom: 5px; }
      &__description {
        color: #878A95;
        font-size: 14px; } } } }
