@import "../../../styles/sizes";

.filter-map {
    width: 260px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
    background-color: white;
    border-radius: 11px; }

.filter {
    &-action {
        height: 100px;
        background-color: #EF6752;
        border-radius: 11px;
        padding: 13px 9px 20px;
        &__buttons {
            display: flex;
            justify-content: space-between; }

        &__btn {
            border: none;
            background-color: white;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
            border-radius: 38px;
            font-size: 0.7em;
            color: #878A95;
            padding: 1px 11px;
            // transition: all ease 0.4s
            cursor: pointer;
            box-sizing: content-box;

            &:hover {
                color: white;
                box-shadow: none;
                background-color: #1D3A65; } } }

    &-content {
        height: 300px;
        background-color: white;
        border-radius: 11px;
        overflow: scroll;
        &::-webkit-scrollbar {
            width: 11px;
            height: 0px;
            &::before {
                background: url(../../../assets/arrowScrollUp.svg); }
            &::after {
                background: url(../../../assets/arrowScrollUp.svg); } }
        &::-webkit-scrollbar-thumb {
            background: #EF6752;
            border-radius: 16px; }
        &::-webkit-scrollbar-track {
            background: #F6F8FB; } }

    &-search {
        width: 100%;
        height: 30px;
        border-radius: 38px;
        margin-bottom: 12px;
        padding-left: 12px;
        font-size: 0.875em; }

    &-item {
        padding-left: 9px;
        padding-top: 10px;
        padding-bottom: 15px;
        font-size: 0.75em;
        line-height: 1.1em;
        font-weight: 500;
        color: #878A95;
        border-bottom: 1px solid #1D3A65;
        cursor: pointer;

        &__title {
            font-size: 1em;
            color: #000;
            margin-bottom: 5px; }

        &__subway {
            margin-bottom: 10px;
            display: flex;
            align-items: self-start;
            span {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                display: block;
                margin-right: 5px; } }

        &__phone {
            font-size: 0.85em;
            margin-bottom: 10px;
            p {
                margin-bottom: 5px; }
            span {
                font-size: 1.18em;
                color: #000; } }
        &__status {
            display: flex;
            &_open {
                color: #93C69B;
                margin-right: 5px; } }

        hr {
            border-top: 1px solid #1D3A65; } } }


.filter-action__btn.active {
    background-color: #1D3A65;
    color: white; }

.filter-item:hover {
    background: rgba(101, 134, 184, 0.15); }

.filter-item.active {
    background: rgba(101, 134, 184, 0.15); }


@media screen and (max-width: $mobileWidth) {
    .filter-map {
        width: 100%; }

    .filter {
        &-action {
            height: auto;
            &__buttons {
                justify-content: flex-start;
                margin-bottom: 10px; }

            &__btn {
                margin-right: 8px;
                &_link {
                    height: 20px;
                    font-size: 0.7em;
                    padding: 5px;
                    margin-top: 20px;
                    border: none;
                    background-color: white;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
                    border-radius: 38px;
                    color: #878A95;
                    padding-left: 11px;
                    padding-right: 11px;
                    &:hover {
                        text-decoration-line: none;
                        color: #878A95; } } } }
        &-content {
            height: 150px; } } }
