@import '../../styles/sizes';

.oformlenie-list__item.active {
    p {
        color: #1D3A65; }
    span {
        background: #1D3A65; } }

.oformlenie-list__item.active_success {
    p {
        color: #8DD76E; }
    span {
        color: white;
        background: #8DD76E; } }

.oformlenie-list__item.active_success:hover {
    cursor: pointer;
    opacity: 0.7; }


.oformlenie {
    width: 100%;
    max-width: 797px;
    &-list {
        display: flex;
        margin-bottom: 30px;
        &__item {
            display: flex;
            align-items: center;
            span {
                margin-right: 10px;
                height: 45px;
                width: 45px;
                border-radius: 50%;
                text-align: center;
                line-height: 45px;
                color: white;
                font-size: 1.75em;
                font-weight: bold;
                background: #878A95; }

            p {
                color: #878A95; } }
        &__item:not(:last-child) {
            margin-right: 45px; } }

    &-content {
        display: flex;
        h4 {
            margin-right: 50px; }

        .form {
            position: static;
            top: 0;
            left: 0;
            transform: none;
            box-shadow: none;
            height: auto;
            max-width: 300px;
            padding: 0;

            &__field {
                height: 40px;
                margin-bottom: 20px; } } }

    .lk-list {
        justify-content: flex-start;
        &__info {
            width: 100%; }
        &__item {
            box-shadow: none; }
        &__content {
            padding-right: 42px; } } }

.btn_oformlenie {
    width: 100%;
    text-transform: none;
    background-color: #C4C4C4;
    margin-top: 20px;
    font-weight: bold;
    max-width: 228px; }
.btn_oformlenie.active {
    background: #EF6752; }

.oformlenie-get__delivery {
    > * {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 60px; } }

.oformlenie-get {

    &__date {
        max-width: 710px; }
    &__list {
        display: flex; }

    &__item {
        border: 2px solid #EF6752;
        width: 120px;
        height: 105px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: space-between;
        padding: 12px 20px;
        cursor: pointer;
        p {
            font-size: 18px; }
        h5 {
            font-weight: bold;
            font-size: 1.125em;
            margin-bottom: 0;
            margin-bottom: 5px;
            white-space: nowrap; }

        p:first-child {
            margin-bottom: 10px; }
        p:last-child {
            color: #EF6752;
            font-weight: bold;
            font-size: 18px !important; } }

    &__item:not(:first-child) {
        margin-left: -2px; }

    &__item:first-child {
        border-radius: 15px 0px 0px 15px; }

    &__item:last-child {
        border-radius: 0px 15px 15px 0px; }

    &__sutki {
        display: flex;
        justify-content: flex-start;
        margin-left: 40px;
        background-color: #F6F8FB;
        border-radius: 15px;
        padding-left: 5px;
        padding-right: 5px;
        margin-right: auto;
        height: 40px;
        align-items: center;
        li {
            padding: 8px 15px;
            color: #878A95;
            font-weight: bold;
            line-height: 20px;
            border-radius: 11px;
            height: 34px;
            cursor: pointer;
            transition: all ease 0.3s; }
        li.active {
            background: #EF6752;
            color: white; } }

    &__interval {
        li {
            font-size: 0.8em;
            color: #878A95; } }

    &__address {
        flex-flow: column;
        .form_delivery {
            width: 100%;
            .lk-addresses {
                &__show {
                    margin-top: 0;
                    &__item {
                        margin-top: 20px;
                        .item-block__field {
                            max-width: 600px;
                            h5 {
                                min-width: 180px; }
                            p {
                                color: #000000; } } } } }


            .form_addresses {
                justify-content: center;
                flex-flow: column;
                > * {
                    margin: 0 auto;
                    width: 500px; }

                .form__field_checkbox label {
                    position: static !important;
                    top: 0 !important;
                    left: 0 !important; } } }
        button {
            font-size: 14px;
            text-transform: none;
            height: 32px;
            padding: 0 44px; }
        label {
            position: absolute;
            right: -30px;
            top: 30px;
            input {
                margin-right: 5px; } } } }

.form_delivery {
    position: static;
    padding: 0;
    box-shadow: none;
    top: 0;
    left: 0;
    transform: none;
    height: auto;
    .form__field {
        height: 40px;
        margin-bottom: 20px;
        &_textarea {
            height: 180px;
            padding: 10px 18px;
            margin-bottom: 5px; } }

    .react-dadata__input {
        height: 40px;
        margin-bottom: 20px;
        border: 2px solid #EF6752;
        border-radius: 26px; }

    p {
        font-size: 0.9em;
        color: #878A95; } }

.oformlenie-confirmation {
    &__footer {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid #EF6752;
        margin-bottom: 14px;
        .oformlenie-confirmation__block {
            margin-right: 5px;
            &:nth-child(2) {
                max-width: 430px; } }
        h4 {
            font-size: 1.125em;
            margin-bottom: 15px; }

        li {
            margin-bottom: 15px;
            p {
                font-weight: bold; } } }

    &__label {
        display: flex;
        width: fit-content;
        align-items: baseline;
        input {
            margin-right: 10px; } } }

.btn-payment {
    position: absolute;
    width: 228px;
    text-transform: none;
    right: 0;
    bottom: 0; }

.oformlenie-get__item.active, .oformlenie-get__item:hover {
    background: rgba(239, 103, 82, 0.2); }

.input-range {
    max-width: 585px;
    height: 14px !important;
    &__track {
        height: 14px !important;
        background: #EF6752 !important;
        &--background {
            background: #F6F8FB !important; } }
    &__slider {
       height: 24px !important;
       width: 24px !important;
       background: #EF6752 !important;
       border: 3px solid white !important;
       margin-top: -18px !important;
       margin-left: -12px !important; } }

.oformlenie-get-range {
    display: flex;
    width: 100% !important;
    max-width: 595px;
    flex-flow: column;
    align-items: center;
    ul {

        max-width: 585px;
        margin-top: 10px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-size: 0.6em;
        line-height: 2.2em; } }

.oformlenie-content_contact {
    align-items: flex-start;
    justify-content: space-between; }


.oformlenie-content_contact .btn.active {
    background: #EF6752;
    color: white; }


.oformlenie-contact__info {
    margin-left: 15px;
    display: flex;
    flex-flow: column;
    ul {
        margin-bottom: 35px;
        li {
            line-height: 1.5em;
            white-space: nowrap; }
        li:not(:last-child) {
            margin-bottom: 10px; } } }

.oformlenie-contact__img {
    flex-basis: 100%;
    img {
        float: right;
        cursor: pointer;
        &:hover {
            opacity: 0.7; } } }

.form_contactData {
    display: flex;
    margin-right: auto; }

.contact-data_notAuth {
    width: 100%;
    a {}

    label {
        display: flex;
        align-items: baseline;
        input {
            margin-right: 5px; }
        p:hover {
            text-decoration-line: underline; } } }

@media screen and (min-width: $mobileWidth + 1) {
    .oformlenie {
        &-content {
            box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.15);
            border-radius: 25px;
            padding: 23px 42px 30px 30px;
            max-width: 793px;
            min-height: 284px; } }

    .btn_oformlenie {
        position: absolute;
        right: 10px;
        bottom: -25px;
        margin: 0;
        transform: translateY(50%); }

    .input-range {
        &__label--value {
            top: -36px !important; }

        &__label--min, &__label--max {
            bottom: -30px !important; } }

    .oformlenie-contact__info {
        margin-left: 15px;
        display: flex;
        flex-flow: column;
        ul {
            margin-bottom: 35px;
            min-width: 300px;
            min-height: 92px;
            li {
                line-height: 1.5em;
                white-space: nowrap; }
            li:not(:last-child) {
                margin-bottom: 10px; } }

        label {
            cursor: pointer;
            margin-bottom: 60px;
            input {
                margin-right: 8px; } }
        p {
            color: #EF6752;
            text-decoration-line: underline;
            font-weight: 500;
            line-height: 1.5em;
            cursor: pointer;
            &:hover {
                text-decoration: none; } } } }

@media screen and (max-width: $mobileWidth) {
    .oformlenie {
        &-list {
            flex-direction: column;
            margin-bottom: 25px;
            &__item {
                margin-bottom: 15px;
                span {
                    height: 32px;
                    width: 32px;
                    line-height: 32px;
                    font-size: 24px; }

                p {
                    font-weight: 700;
                    font-size: 18px; } } }

        &-content {
            width: 100%;
            flex-direction: column;

            h4 {
                margin-bottom: 16px; } }

        .lk-list {
            &__content {
                box-shadow: none;
                padding: 0; }
            .active {
                color: #1D3A65;
                border-bottom: 1px solid #1D3A65; }

            &__item {
                padding: 4px 7px;
                font-weight: 700; }

            &__item.active {
                box-shadow: none; } } }

    .btn_oformlenie {
        margin-bottom: 100px;
        margin: 0 auto; }

    .oformlenie-get__delivery {
        > * {
            flex-direction: column; } }

    .oformlenie-get {
        &__date {
            position: relative;
            h4 {
                margin-bottom: 15px; }
            //.list-faded
            //    width: 30px
            //    height: 100%
            //    position: absolute
            //    right: 0
            //    top: 0
 }            //    background: rgba(255, 255, 255, .5)

        &__list {
            display: flex;
            overflow-x: auto;
            max-width: 100%;
            &::-webkit-scrollbar {
                width: 0;
                -ms-overflow-style: none;
                overflow: -moz-scrollbars-none; } }

        &__range {
            h4 {
                margin-bottom: 5px; }
            p {
                font-size: 12px;
                color: #878A95;
                margin-bottom: 25px; } }

        &__sutki {
            margin-left: 0px;
            margin-right: 0px; }

        &__interval {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;

            h4 {
                margin-bottom: 5px; } }

        &__address {
            .form_delivery {
                .form_addresses {
                    > * {
                        width: 100%; } } }
            button {
                margin: 0 auto; }

            label {
                position: static;
                top: 0;
                left: 0;
                display: flex;
                margin-top: 10px;
                align-items: center;
                flex-direction: row !important;
                input {
                    margin-right: 10px; } } } }


    .oformlenie-confirmation {
        &__block {
            margin-bottom: 10px; }
        &__footer {
            margin-top: 17px;
            flex-direction: column;
            h4 {
                font-size: 16px;
                margin-bottom: 12px; }

            li {
                p {
                    margin-bottom: 5px; } } } }

    .input-range {
        display: flex;
        justify-content: center;
        &__track {
            position: relative !important;
            max-width: 325px;
            &--background {
                position: relative;
                width: 100%; } }

        &__label--min {
            span {
                left: 100% !important; } }
        &__label--max {
            span {
                left: auto !important;
                right: 50% !important; } } }

    .oformlenie-get-range ul {
        max-width: 335px; }

    .oformlenie-get__vivoz {
        .container_map {
            padding: 0;
            .filter-content {
                height: 300px; } } }

    .btn_oformlenie_wrapper {
        display: flex;
        justify-content: center;
        width: 100%; }

    .oformlenie-contact__info {
        flex-flow: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        margin-left: 0px;
        img {
            float: right;
            cursor: pointer;
            &:hover {
                opacity: 0.7; } }

        ul {
            margin-bottom: 25px;
            li {
                line-height: 24px;
                margin-bottom: 10px;
                white-space: auto; }
            li:not(:last-child) {
                white-space: nowrap;
                margin-bottom: 0px; } } }

    .oformlenie-contact__checkbox {
        margin-bottom: 55px;
        label {
            cursor: pointer;
            margin-bottom: 30px;
            input {
                margin-right: 8px; } }
        p {
            color: #EF6752;
            text-decoration-line: underline;
            font-weight: 500;
            line-height: 1.5em;
            cursor: pointer;
            &:hover {
                text-decoration: none; } } }

    .form_contactData {
        max-width: 100% !important;
        .save_btn {
            padding: 8px 43px;
            margin: 0;
            margin-left: auto !important; }
        .company {
            color: #EF6752;
            text-decoration-line: underline;
            font-weight: 500;
            line-height: 1.5em;
            cursor: pointer;
            margin-bottom: 25px;
            &:hover {
                text-decoration: none; } } }

    .oformlenie-payment__checkbox {
        margin-top: 20px;
        label {
            cursor: pointer;
            margin-bottom: 30px;
            input {
                margin-right: 8px; } }
        p {
            color: #EF6752;
            text-decoration-line: underline;
            font-weight: 500;
            line-height: 1.5em;
            cursor: pointer;
            &:hover {
                text-decoration: none; } } }

    .oformlenie-confirmation {
        .cart-details {
            margin-top: 20px; }

        .cart__promo {
            order: 2; }

        .cart-bottom {
            order: 4; }

        .cart-order {
            background: white;
            margin-bottom: 15px;
            &__itog {
                padding: 20px 15px; } } } }
