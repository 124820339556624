@import "../../styles/sizes";

.container_recepies {
    max-width: 1180px;
    padding-top: 0;
    min-height: 100vh;
    h2 {
        margin-bottom: 30px; }

    .recepies-cards a:hover {
        text-decoration-line: none; } }
.recipe .slick-slide:hover {
    background: #F6F8FB;
    border-radius: 13px; }

.recipe .card__img:hover {
    background: #F6F8FB; }

.recipe a:hover {
    text-decoration-line: none; }
.recipe .card__img {
    margin-bottom: 0; }

@media screen and (max-width: $mobileWidth) {
    .container_recepies {
        min-height: auto;
        width: 100%;
        h2 {
            margin-bottom: 10px;
            font-size: 22px; } }

    .recipe a:hover {
        width: 100%; } }
