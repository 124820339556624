@import "../../styles/sizes";

.container_catalog {
    max-width: 1180px;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 0;
    .catalog-product {
        min-height: auto !important; } }

.catalog-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }

.catalog-category-page {
    max-width: 825px;
    flex-basis: 100%;
    min-height: 700px;
    display: flex;
    flex-flow: column;

    h2 {
        margin-bottom: 48px; }

    .placeholder-fish {
        margin: 0 auto; }
    .placeholder-desc {
        margin: 0 auto;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #000; }

    a:hover {
        text-decoration: none; } }

.card {
    width: 255px !important;
    flex-basis: auto !important;
    &-top__img {
        width: 254px;
        height: 176px;
        object-fit: cover;
        background: white;
        background-color: white; } }

@media screen and (max-width: $mobileWidth) {
    .container_catalog {
        width: 100%;
        min-height: auto;

        h3 {
            font-weight: 700;
            font-size: 22px;
            line-height: 24px;
            color: #1D3A65;
            margin-bottom: 10px; }

        .categories__btn {
            height: 25px;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
            background: #FFF;
            font-size: 14px;
            line-height: 110%;
            border: none;
            transition: all ease .3s;
            border-radius: 8px;
            font-weight: 500;
            color: #878787;
            padding: 5px 25px;
            margin-bottom: 13px; } }
    .catalog-content {
        .container {
            padding: 0;
            margin-top: 15px;
            .categories-title {
                display: none; } } }
    .card {
        width: 100% !important;
        flex-basis: auto !important;
        &-top__img {
            width: 254px;
            height: 176px;
            object-fit: cover;
            background-color: inherit; } } }
