@import "../../../../styles/sizes";

.product-review {
    &__top {
        margin-bottom: 8px !important;
        div:not(:last-child) {
            margin-bottom: 5px;
            color: #000000;
            font-size: 0.88em;
            line-height: 1.18em; }
        > * {
            display: flex;
            align-items: center; }
        .draw-stars svg {
            width: 16px;
            height: auto;
            margin-right: 4px; } }

    &__bottom {
        margin-bottom: 10px; }

    &__title {
        font-weight: bold;
        color: #000000;
        font-size: 0.88em;
        line-height: 1.18em;
        margin-right: 10px;
        margin-bottom: 0; } }

.product-review {
    width: 100%; }
.product-review:not(:last-child) {
    border-bottom: 1px solid #EF6752; }

@media screen and (max-width: $mobileWidth) {
    .product-review {
        padding: 12px 0;
        margin: 0 !important;
        &__top {
            h5 {
                font-size: 14px;
                color: #000000;
                font-weight: 700; } }

        &__bottom {
            margin: 0;
            font-size: 14px;
            line-height: 16px; } } }

