@import "../../../styles/sizes";

.shop-link {
    font-size: 0.75em;
    line-height: 1.1em;
    font-weight: 500;
    color: #878A95;
    flex-basis: 25%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > div {
        width: 100%; }

    &:hover {
        border: 3px solid #EDE8E8;
        border-radius: 13px;
        padding: 7px; }

    > a {
        text-decoration-line:  none; }

    &__img {
        width: 254px;
        background-color: grey;
        height: 167px;
        object-fit: cover;
        border-radius: 8px;
        margin-bottom: 15px; }

    &__title {
        font-size: 1.5em;
        line-height: 1.1em;
        color: #1D3A65;
        font-weight: bold;
        margin-bottom: 5px; }

    &__subway {
        margin-bottom: 10px; }

    &__phone {
        font-size: 0.85em;
        margin-bottom: 10px;
        p {
            margin-bottom: 5px; }
        span {
            font-size: 1.18em;
            color: #000; } }
    &__status {
        display: flex;
        &_open {
            color: #93C69B;
            margin-right: 5px; } } }

@media screen and (max-width: $mobileWidth) {

    .shop-link {
        flex-basis: auto;
        padding: 0px;
        width: 100%;
        margin-bottom: 30px;
        a {
            width: 100%; }

        &__img {
            max-width: 100%;
            width: 100%;
            height: 200px; }

        &__title {
            font-size: 16px; } } }
