@import "../../styles/sizes";

@media screen and (max-width: $mobileWidth) {
  .modal-backdrop {
    height: 100%;
    width: 100%;
    transform: translateY(48px); }

  .modal {
    height: 100%;
    width: 100%;
    padding: 60px 0 10px; }

  .noPadding {
    padding: 0 !important; }

  .modal-dialog {
    margin: 0; }

  .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0;
    & > div {
      width: 100%; } }

  .modal-content {
    border: none;
    border-radius: 0;
    background: none;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%; }

  .modal-body__child {
    top: 28px; }


  @media (max-width: 340px) {
    .modal-body__child {
      max-width: 300px !important; } } }
