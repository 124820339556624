@import "../../styles/sizes";

$colorHeader: #2F323A;
$colorRed: #EF6752;

.header {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    color: $colorHeader;

    &__top, &__bottom {
        width: 100%; }

    &__top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 45px;

        > * {
            display: flex;
            align-items: center; } }

    &__logo {
        margin: auto; }

    &__info {
        flex-flow: column;
        justify-content: center;
        align-items: start;
        min-width: 132px;
        flex-grow: 1;
        max-width: 300px; }


    &__clock {
        display: flex;
        justify-content: space-between;
        width: 116/132*100%;
        p {
            font-size: 0.875em;
            color: #E5E5E5; } }

    &__personal {
        position: relative;
        justify-content: space-between;
        min-width: 305px; }

    &__icons {
        flex-basis: 100%;
        max-width: 260px;
        display: flex;
        justify-content: space-between;
        > *:hover {
            cursor: pointer;
            path {
                fill: #EF6752; } }

        &-img:hover + .header__hint {
            height: 20px; } }

    &__icon {
        position: relative; } }

.header__hint {
    transition: all ease 0.2s;
    height: 0;
    overflow: hidden;
    right: 50%;
    bottom: -25px;
    color: $colorRed;
    transform: translateX(50%);
    position: absolute;
    white-space: nowrap;
    z-index: 200; }

.search {
    position: absolute;
    left: 0;
    transform: translateX(-100%);
    height: 30px;
    padding-left: 5px;
    border-bottom: 1px solid #2F323A; }

.search.active {
    display: block; }

.menu_contacts {
    z-index: 100;
    height: 0;
    overflow: hidden;
    transition: all ease 0.3s;
    position: absolute;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    padding-left: 11px;
    padding-right: 19px;
    font-size: 0.8em;
    line-height: 1.37em;
    font-weight: normal;
    width: 213px;
    top: 25px;
    left: -12px;
    background-color: white;
    box-shadow: 0px 15px 13px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 25px 25px;

    .menu__item {
        width: 100%;
        &:not(:last-child) {
            border-bottom: 1px solid #EF6752; }

        a {
            letter-spacing: -0.408px;
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 8px;
            &:hover {
                color: #EF6752; } } } }


.menu__item_contacts {
    position: relative; }


.menu__item_contacts:hover .menu_contacts {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 180px; }

.menu__item_contacts:hover .menu__link_header {
    color: #EF6752; }

.menu_header-lk {
    z-index: 100;
    transition: height ease 0.2s;
    height: 0;
    // margin-top: 5px
    flex-flow: column;
    background-color: white;
    box-shadow: 0px 13px 13px rgba(0, 0, 0, 0.25);
    width: 150px;
    position: absolute;
    left: -14px;
    border-radius: 0px 20px 20px 20px;
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden;
    li {
        padding: 10px 0;
        border-bottom: 1px solid #EF6752;
        font-size: 14px;
        &:hover {
            color: #EF6752; }
        a {
            text-decoration-line: none; } } }
.header__icon_lk:hover {
    .menu_header-lk {
        height: 95px;
        padding-top: 5px; }
    span {
        box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.25); } }

.header__icon_lk {
    img {
        position: relative;
        z-index: 2; }
    span {
        z-index: 1;
        position: absolute;
        top: -10px;
        left: -14px;
        display: block;

        border-radius: 20px 20px 0 0;
        width: 50px;
        height: 50px;
        background: #fff; } }

.header__icon_cart {
    width: auto;
    min-width: 90px;
    max-width: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        color: #2F323A;
        > img {
            margin-right: 10px; }
        > p {
            color: inherit; }
        &:hover {
            color: inherit;
            text-decoration-line: none; } }

    .cart {
        right: -60px;
        margin-top: 0px;
        padding-top: 20px;
        top: 22px; } }
.header__icon_cart:hover {
    .cart {
        display: flex; }
    .header__amount {
        color:#EF6752 {} } }

#animate-height-catalog {
    position: absolute;
    left: 0;
    background-color: white;
    z-index: 101;
    width: 100%;
    box-shadow: 0px 16px 13px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 25px 25px; }

.menu_block__wrapper_catalog {
    padding: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100% !important; }


@media screen and (min-width: 401px) {
    .header__logo {
        img {
            width: 168px; } } }

@media screen and (max-width: 400px) {
    .header__logo {
        img {
            width: 120px; } } }

@media screen and (max-width: $mobileWidth) {
    .header {
        z-index: 10000;
        &__top {
            align-items: center;
            flex-direction: row;
            margin-bottom: 4px;
            padding: 6px 0 10px;
            a {
                margin-right: 0 !important; } }
        &__info {
            flex-flow: row;
            cursor: pointer;
            margin-right: 18px;
            justify-content: flex-start;
            align-items: flex-start;
            min-width: auto;
            flex-grow: 0;
            max-width: inherit; }
        &__logo {
            flex: 1;
            margin: 0;
            img {
                height: 24px;
                max-width: 168px; } }
        &__personal {
            min-width: inherit; }
        &__icons {
            flex-basis: auto;
            max-width: inherit;
            margin-left: 10px;
            height: 32px;
            align-items: center;
            &-img {
                max-width: 18px;
                max-height: 18px;
                margin-left: 15px; }
            &-img:hover + .header__hint {
                height: 18px; } } }
    .search {
        background: #FFF;
        flex: 1;
        transform: none;
        position: relative;
        display: flex;
        padding-bottom: 3px;
        input {
            flex: 1 1 auto;
            width: 115px; } }

    .header__icon-lk {
        padding: 7px 0; }
    .header__icon_cart {
        min-width: auto; }
    .active-header-icon {
        box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.25);
        border-radius: 20px 20px 0 0;
        box-sizing: content-box;
        padding: 7px 10px;
        position: relative; }
    .lk_card_header {
        z-index: 100;
        transition: height ease 0.2s;
        flex-flow: column;
        background-color: white;
        box-shadow: 0px 13px 13px rgba(0, 0, 0, 0.25);
        width: 150px;
        position: absolute;
        right: 0;
        border-radius: 20px 0px 20px 20px;
        padding-left: 10px;
        padding-right: 10px;
        overflow: hidden;
        li {
            padding: 10px 0;
            &:first-of-type {
                border-bottom: 1px solid #EF6752; }
            font-size: 14px;
            &:hover {
                color: #EF6752; }
            a {
                text-decoration-line: none;
                color: #000; } } }


    .menu {
        display: flex;
        flex-direction: column;
        background-color: #FFF;
        position: absolute;
        left: 0;
        top: 57px !important;
        max-width: 300px;
        height: 100%;
        justify-content: flex-start !important;
        &__items {
            width: 100%;
            background: white; }

        .accordion {
            margin: 0;
            border: none;

            a {
                color: black;
                display: block; }

            &__item {
                padding: 15px 0 6px 23px;
                margin: 0 !important;
                font-weight: 400;
                font-size: 14px;
                border-bottom: 1px solid #EF6752; }
            a {
                font-weight: 400; }
            &__title {
                font-weight: 600; }
            &__body {
                padding: 0; }
            &__toggle {
                padding-top: 20px;
                padding-bottom: 0; } }

        &__city {
            padding: 15px;
            border-bottom: 1px solid #EF6752;
            font-size: 16px;
            width: 100%; }
        &__item {
            font-weight: 600;
            width: 100%;
            font-size: 16px;
            color: #2F323A;
            a {
                color: #2F323A;
                width: 100%; }
            padding: 20px 15px 0;
            &:last-of-type {
                padding-bottom: 15px; }
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &__accordion {
                padding-top: 0; } } }

    .submenu {
        background: #FFFFFF;
        border-radius: 15px;
        position: relative;
        max-width: 345px;
        padding: 65px 40px 25px;
        color: #2F323A;
        font-weight: 500;
        a {
            color: inherit;
            display: block; }

        &__buttons {
            position: absolute;
            top: 12px;
            padding: 0 12px;
            width: 100%;
            left: 0;
            display: flex;
            align-items: center;
            z-index: 5;
            justify-content: space-between;
            & > div {
                cursor: pointer; } }

        &__item {
            padding: 10px 0;
            width: 100%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between; } }
    .header__icon_cart:hover {
        .cart {
            display: flex;
            align-items: center;
            justify-content: space-between; } } }

@media screen and (max-width: 350px) {
    .header__logo {
        img {
            width: 100px; } }
    .header__icons-img {
        max-width: 14px;
        max-height: 14px;
        margin-left: 10px; } }
