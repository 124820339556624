$colorRed: #EF6752;
$colorGrey:  #878A95;
$colorOrderBG: #F6F8FB;

@import "../../styles/sizes";

.cart {
    position: absolute;
    top: 40px;
    right: -30px;
    z-index: 101;
    display: none;
    width: 400px;
    height: 370px;
    background-color: white;
    box-shadow: 0px 15px 13px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 25px 25px;
    flex-flow: column;

    &-items {
        transition: all ease 0.3s;
        height: 220px;
        overflow: scroll;
        padding: 5px 8px;
        &::-webkit-scrollbar {
            width: 11px;
            height: 0px;
            &::before {
                background: url(../../assets/arrowScrollUp.svg); }
            &::after {
                background: url(../../assets/arrowScrollUp.svg); } }
        &::-webkit-scrollbar-thumb {
            background: $colorRed;
            border-radius: 16px; }
        &::-webkit-scrollbar-track {
            background: $colorOrderBG; } }


    &-item:not(:last-child) {
        border-bottom: 1px solid $colorRed; }

    &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 43px;

        &__img {
            width: 50px;
            height: 50px;
            object-fit: contain;
            margin-right: 5px; }

        &__description, &__costs {
            text-align: left;
            font-weight: 500;
            letter-spacing: -0.408px;
            font-size: 0.5625em;
            line-height: 1.5em; }

        &__description {
            width: 100%;
            margin-right: 5px; }


        &__right {
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-width: 170px;
            flex-basis: 100%; }


        &__name, &__cost {
            color: black; }

        &__name {
            text-align: left; }

        &__articul, &__amount {
            color: $colorGrey; }

        &__counter {
            display: flex;
            width: 86px;
            justify-content: space-between;
            align-items: center; }

        &__costs {
            min-width: 40px; }

        &__show {
            width: 40px;
            height: 20px;
            border: 1px solid $colorRed;
            border-radius: 12px;
            text-align: center;
            color: $colorGrey;
            font-size: 0.625em; }

        &__btn:hover {
                cursor: pointer; } }
    &-order {
        background-color: $colorOrderBG;
        padding: 12px 10px;

        &__top {
            font-size: 0.75em;
            line-height: 1em;
            letter-spacing: -0.408px;
            color: $colorGrey; }

        &__desc {
            color: black;
            span {
                margin-left: 10px;
                color: $colorGrey; } }
        &__itog {
            display: flex;
            justify-content: space-between;
            align-items: center; }

        &__itogovaya {
            p:first-child {
                color: black;
                font-size: 1.17em;
                font-weight: 500;
                line-height: 1.3em; }
            p:nth-of-type(2) {
                font-size: 0.83em; } }

        &__description {
            font-size: 0.83em;
            color: #878A95;
            margin-top: 5px; }

        &__btn {
            background-color: $colorRed;
            border-radius: 12px;
            color: white;
            width: 130px;
            font-size: 1.16em;
            height: 26px !important;
            letter-spacing: -0.408px;
            font-weight: bold;
            text-transform: none !important;
            line-height: 22px;
            margin-bottom: 0px !important; } }


    &-bottom {
        padding: 10px 10px 15px 10px;
        font-size: 0.75em;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
        color: #000;

        &__graph {
            display: flex;
            flex-flow: column;
            width: 100%; } } }

.graph {
    &__points {
        display: flex;
        justify-content: space-between; }

    &__img {
        width: 100%;
        height: 14px;
        background: #F6F8FB;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        border-radius: 18px;
        margin-bottom: 5px; }

    &__green {
        max-width: 100%;
        transition: all ease 0.15s;
        height: 100%;
        background-color: #8DD76E;
        border-radius: 18px; } }

.cart.active {
    display: flex; }

.cart-items_isEmpty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 20px;
    font-weight: 500; }


@media screen and (max-width: $mobileWidth) {
    .cart {
        &-items {
            height: auto;
            overflow: auto; } } }
