@import "../../styles/sizes";

.container_recipe {
    max-width: 1180px;
    padding-top: 0; }
.recipe {
    &-title {
        margin-bottom: 15px; }
    &-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
        &__ingredients {
            max-width: 395px;
            flex-basis: 100%; }
        &__img {
            width: 645px;
            height: 400px;
            object-fit: cover;
            border-radius: 14px;
            background: #C4C4C4; }
        &__info {
            font-size: 1em;
            font-weight: 500;
            line-height: 1.2em;
            color: #EF6752;
            margin-bottom: 5px;
            span {
                color: black; } } }

    .ingredients-list {
        &__item {
            font-size: 0.88em;
            font-weight: normal;
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding-top: 5px;
            padding-bottom: 5px;
            &:not(:last-child) {
                border-bottom: 0.5px solid #6586B8; } } }

    &-cook {
        display: flex;
        flex-wrap: wrap;
        margin-left: -12px;

        &__card {
            max-width: 276px;
            margin-bottom: 25px;
            padding-left: 12px;
            padding-right: 12px;
            box-sizing: border-box; }

        &__img {
            width: 250px;
            height: 250px;
            border-radius: 14px;
            background: #C4C4C4;
            object-fit: cover;
            margin-bottom: 15px; } }

    &-video {
        margin-bottom: 60px; }

    .cards {
        display: flex;
        flex-flow: column; }

    .slick-track {
        align-items: flex-start; } }

.block_slider_recipe-products {
    .slick-track {
        justify-content: flex-start; }

    .slick-slide {
        max-width: 275px; } }

@media screen and (max-width: $mobileWidth) {
    .recipe {
        &-title {
            font-size: 20px; }

        &-top {
            flex-direction: column;
            h3 {
                font-size: 16px;
                color: #000;
                margin: 10px 0; }

            &__img {
                width: 100%;
                height: 230px;
                margin-bottom: 12px; } }

        &-cook {
            margin-left: 0px;

            &__card {
                width: 100%;
                max-width: inherit;
                margin-bottom: 10px;
                align-items: flex-start;
                justify-content: flex-start;
                padding-left: 0px;
                padding-right: 0px; }

            &__img {
                width: 100%;
                height: 160px; } }

        &-video {
            display: flex;
            flex-direction: column;
            h3 {
                font-size: 24px;
                margin-bottom: 20px;
                margin-top: 25px; }
            iframe {
                flex: 1; } } } }
