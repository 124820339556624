@import "../../../styles/sizes";

.form_job {
    display: flex;
    position: relative;
    box-shadow: none;
    padding: 0;


    .form-field {
        border: 2px solid #EF6752;
        height: 40px;
        border-radius: 26px;

        &_input, &_textarea {
            padding-left: 15px; }

        &_textarea {
            padding-top: 10px;
            height: 180px; }

        &_btn {
            width: 180px;
            background-color: #EF6752;
            margin-left: auto;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24); } } }

@media screen and (min-width: $mobileWidth + 1) {
    .form_job {
        width: 300px;
        background-color: inherit;
        height: 420px;
        top: 0;
        left: 0;
        z-index: 1;
        transform: none; } }

@media screen and (max-width: $mobileWidth) {

    .form_job {
        position: static;
        transform: none;
        width: 100%;

        .form-field {
            margin-bottom: 20px !important;

            &_btn {
                color: white;
                text-transform: uppercase;
                line-height: 19px;
                margin-bottom: 25px; } } } }
