@import "../../styles/sizes";

@media screen and (max-width: $mobileWidth) {
  .accordion {
    background: none;
    width: 100%;

    a {
      color: black; }


    &__toggle {
      width: 100%;
      border: none;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__open {
        svg {
          transition: all .3s linear;
          transform: rotate(-180deg) !important; } }
      &__close {
        svg {
         transition: all .3s linear;
         transform: rotate(0) !important; } } } } }
