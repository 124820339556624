@import "../../styles/sizes";

.container_aboutUs {
    max-width: 1180px;
    padding-top: 0; }

.about {
    margin: 0 auto;

    &-title {
        margin-bottom: 20px;
        font-size: 2em;
        line-height: 1.06em;
        color: #EC9F87;
        font-weight: bold; }

    &-block {
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;

        > :first-child {
            margin-right: 40px; }

        &__img {
            max-width: 500px;
            max-height: 300px;
            height: auto;
            border-radius: 20px; }

        &__title {
            margin-bottom: 10px; }

        &__text {
            text-align: justify;
            white-space: pre-line; } } }

@media screen and (max-width: $mobileWidth) {
    .container_aboutUs {
        margin-bottom: 30px; }

    .about {
        &-title {
            margin-bottom: 10px;
            font-size: 22px;
            line-height: 24px; }

        &-block {
            flex-direction: column;
            margin-bottom: 24px;

            &__img {
                width: 100%;
                max-width: auto; }

            &__title {
                margin-bottom: 15px;
                font-size: 24px;
                margin-top: 20px; }

            &__text {
                font-size: 14px; } } } }
