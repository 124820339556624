$colorRed: #EF6752;

@import "../../../styles/sizes";

.lk-personal-data {
    position: relative;
    &__content {
        display: flex;
        flex-flow: column;
        align-items: center;
        ul li {
            min-height: 20px;
            margin-bottom: 10px;
            align-items: center; } }
    &__list {
        li {
            display: flex;
            min-width: 180px;
            flex-basis: 100%;
            font-weight: bold; } }

    &__link {
        color: $colorRed;
        text-decoration-line: underline;
        cursor: pointer;
        &:hover {
            text-decoration: none; } }

    &__pencil {
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0; }

    &__change {
        display: flex; }

    .form {
        position: static;
        box-shadow: none;
        height: auto;
        width: 300px;
        top: 0;
        bottom: 0;
        transform: none;
        padding: 0;
        margin-bottom: 50px;
        &__field {
            margin-bottom: 20px;
            height: 40px; }

        &-btn {
            text-transform: none; } }

    &__data {
        margin-bottom: 120px; } }

.lk-personal-data__list.active {
    li {
        height: 40px;
        margin-bottom: 20px; } }

.lk-favourite, .lk-history {
    min-height: 350px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    &__title {
        height: auto;
        color: rgba(29, 58, 101, 0.54);
        font-size: 2em;
        line-height: 1.1em;
        font-weight: 500; } }

.lk-addresses {

    .btn {
        padding: 10px 50px;
        text-transform: none; }

    .container_map {
        margin-bottom: 20px; } }


.lk-favourite {
    padding: 25px;
    justify-content: flex-start;
    .recepies-cards {
        justify-content: flex-start;
        flex-flow: row;
        flex-wrap: wrap; }
    .card {
        width: 240px !important;
        padding-left: 10px;
        padding-right: 10px;
        &-top__img {
            max-width: 220px; } }

    &__title {
        margin: 0 auto; } }

.lk-history {
    align-items: start;
    &__carts {
        display: flex;
        flex-flow: column;
        width: 100%;
        min-height: 350px; }

    &__title {
        text-align: center;
        margin-bottom: auto !important;
        margin: auto;


        h4 {
            margin-bottom: 20px;
            color: #6586B8;
            font-size: 1.125em; } }

    &__details {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4 {
            color: #1D3A65;
            font-weight: bold;
            font-size: 1.5em;
            line-height: 1.1em; } }
    &__icons {
        img {
            margin-left: 24px; } }

    &__price {
        font-weight: bold;
        font-size: 1.125em;
        letter-spacing: -0.408px;
        color: #000;
        text-align: right;
        margin-top: 20px; }

    &__items:not(:last-child) {
        margin-bottom: 60px; }

    &__item {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        border-bottom: 1px solid #EF6752;

        &-left, &-right {
            display: flex; }

        &-right {
            display: flex;
            justify-content: space-between;
            align-items: center; }

        &__block {
            font-weight: 500;
            min-width: 80px;
            p {
                font-size: 1em;
                line-height: 1.37em;
                color: #000000; }
            span {
                font-size: 0.85em;
                line-height: 1.57em;
                color: #878A95; } }

        &__count {
            border: 1px solid #EF6752;
            border-radius: 18px;
            font-size: 0.85em;
            line-height: 1.57em;
            color: #878A95;
            padding: 3px 15px;
            width: 55px;
            text-align: center; }

        &__img {
            width: 60px;
            height: 40px;
            object-fit: cover;
            margin-right: 10px; } } }

.form_changePassword {
    height: 400px;
    padding: 70px 70px 60px;
    h2 {
        font-weight: 900;
        font-size: 44px;
        line-height: 110%; }

    p {
        text-align: center;
        color: red;
        transform: translateY(-8px); }

    .password-succeed {
        text-align: center;
        margin: auto; } }

@media screen and (min-width: $mobileWidth + 1) {
    .lk-personal-data {
        &__content {}
        padding-top: 50px;
        padding-bottom: 25px;
        min-height: 350px; }

    .lk-history {
        &__details {
            img {
                cursor: pointer;
                &:hover {
                    opacity: 0.6; } } }

        &__item {
            &-left {
                flex-basis: 100%;
                max-width: 600px;
                a {
                    display: flex;
                    width: 100%; } }

            &-right {
                max-width: 250px;
                flex-basis: 100%; } } } }

@media screen and (max-width: $mobileWidth) {
    .lk-personal-data {
        button {
            padding: 8px 75px;
            margin: 0 auto; }
        .form {
            width: 100%; }
        &__content {
            position: relative;
            align-items: flex-start;

            .data-name {
                font-weight: bold; }
            .form-data-name {
                font-weight: bold;
                margin-bottom: 8px; } }
        &__change {
            width: 100%; }

        &__pencil {
            top: 0;
            right: 0; }

        &__data {
            margin-bottom: 35px; } }

    .lk-addresses {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .btn {
            padding: 8px 40px;
            min-width: 165px; } }


    .lk-history {
        &__details {
            h4 {
                font-size: 22px;
                line-height: 24px; } }

        &__price {
            margin-top: 15px; }

        &__item {
            flex-direction: column;
            padding: 10px 0 25px;

            &-left {
                display: flex;
                justify-content: flex-start;
                margin-bottom: 20px; }

            &-right {
                width: 100%;
                flex-direction: row-reverse; }

            &__img {
                width: 70px;
                height: 50px; } } } }
