@import "../../../styles/sizes";
.reviews-items {
    max-width: 700px;
    flex-basis: 100%;
    display: flex;
    align-items: center;
    // justify-content: center
    width: 100%;
    flex-flow: column;
    margin-bottom: 30px;
    min-height: 610px; }

.review-item {
    border-bottom: 1px solid #EF6752;
    padding-bottom: 25px;
    padding-top: 25px;
    width: 100%;
    > *:not(:last-child) {
        margin-bottom: 15px; }

    &:first-of-type {
        padding-top: 0; }

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center; }

    .draw-stars {
        svg {
            width: 16px;
            margin-right: 4px; } }

    &__date {
        color: rgba(0, 0, 0, 0.54);
        font-size: 0.85em; }
    &__name {
        font-weight: bold; }

    &__attachment {
        > img {
            height: 100px; }
        > video {
            height: 300px; } } }




.form_registration {
    display: flex; }


@media screen and (max-width: $mobileWidth) {
    .review-item {

        &__attachment {

            > video {
                width: 100%;
                height: auto; } } } }
