@import "../../styles/sizes";

.container_reviews {
    padding-top: 0;
    max-width: 1180px;
    h2 {
        margin-bottom: 20px; } }


.reviews-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__right {
        flex-basis: 100%;
        max-width: 793px;
        align-items: center;
        display: flex;
        flex-flow: column; } }

.review-load {
    margin: 0 auto;
    background: #1F3A64;
    color: #FFFFFF;
    padding: 13px 95px;
    text-transform: uppercase;
    border: none;
    border-radius: 25px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
    &:hover {
        transition: all ease 0.2s;
        opacity: 0.8; } }



@media screen and (max-width: $mobileWidth) {

    .container_reviews {
        h2 {
            margin-bottom: 13px;
            font-size: 22px; } }

    .reviews-content {
        flex-direction: column; }

    .review-load {
        margin: 0px;
        margin-bottom: 60px; } }
