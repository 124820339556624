.catalog-product {
    flex-basis: 100%;
    .card {
        width: 254px; } }

@import "../../../../styles/sizes";

@media screen and (max-width: $mobileWidth) {
    .catalog-product {
        flex-basis: auto;
        max-width: auto; }
    .card {
        width: auto; } }
