@import "../../../styles/sizes";

.search {
    z-index: 1; }
.search-show {
    position: absolute;
    top: 30px;
    left: 0px;
    width: 200%;
    height: 200px;
    padding: 0px;
    box-sizing: border-box;
    background-color: white;
    background: white;
    box-shadow: 0px 13px 13px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 25px 25px;
    display: flex;
    flex-flow: column;

    .product-list {
        height: 190px;
        padding: 0px 4px;
        padding-left: 8px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 11px;
            height: 0px; }
        &::-webkit-scrollbar-thumb {
            background: #EF6752;
            border-radius: 16px; }
        &::-webkit-scrollbar-track {
            background: #F6F8FB;
            border-radius: 0px 0px 25px 0px; } }


    &__card {
        display: flex;
        font-size: 13px;
        cursor: pointer;
        letter-spacing: -0.308px;
        color: black;
        font-weight: 500;
        padding: 5px 0;
        border-bottom: 1px solid #EF6752;
        align-items: center;
        &:hover {
            text-decoration-line: none;
            color: #EF6752; }

        img {
            width: 40px;
            height: 30px;
            object-fit: contain;
            margin-right: 10px; }

        p {
            text-align: justify; } } }

@media screen and ( max-width: $mobileWidth ) {
    .search {
        position: static; }
    .search-show {
        max-width: 375px;
        left: 0px;
        top: 45px;
        width: 100%; } }





