@import "../../../styles/sizes";

.recepies-filter {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    &__buttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 30px;
        flex-wrap: wrap; }
    &__button {
        height: 36px;
        padding-left: 35px;
        padding-right: 35px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
        border-radius: 38px;
        color: #878A95;
        font-size: 1em;
        line-height: 110%;
        border: none;
        transition: all ease .3s;
        margin-bottom: 15px;
        &:not(:last-child) {
            margin-right: 25px; } }

    .review-load {
        margin: 0 auto;
        margin-top: 20px; } }


.recepies-filter__button.active {
    background-color: #1D3A65;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    color: white; }

@media screen and (max-width: $mobileWidth) {
    .recepies-filter {
        &__buttons {
            margin-bottom: 12px; }
        &__button {
            padding: 5px 16px;
            font-size: 14px;
            margin-bottom: 8px;
            &:not(:last-child) {
                margin-right: 8px; } } }

    .recepies-more_info {
        display: flex;
        justify-content: center;
        width: 100%;
        button {
            color: white;
            padding: 13px 0;
            width: 100%;
            background: #1F3A64;
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24);
            border-radius: 25px;
            margin-bottom: 35px;
            border: none; } } }
