@import "../../styles/sizes";

.container_cart {
    max-width: 1165px;
    padding-top: 0;
    min-height: 675px;
    position: relative;

    .cart {
        display: flex;
        position: relative;
        box-shadow: none;
        width: 100%;
        height: 545px;
        flex-flow: row;
        justify-content: space-between;

        &-items {
            max-width: 780px;
            width: 100%;
            height: 545px;
            display: flex;
            flex-direction: column;
            &_empty {
                display: flex;
                flex-flow: column;
                align-items: center;
                margin: auto;
                max-width: 250px;
                > * {
                    text-align: center; }
                img {
                    margin-bottom: 35px; }
                p:nth-of-type(1) {
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 22px; }
                p:nth-of-type(2) {
                    font-size: 14px;
                    line-height: 120%;
                    margin-bottom: 20px; } } }

        &-item {
            height: 68px;

            &__img {
                max-width: 70px;
                height: 60px;
                width: 70px;
                object-fit: fill;
                margin-right: 10px; }

            &__counter {
                align-items: center;
                min-width: 120px;
                input {
                    width: 57px; }
                img {
                    width: 28px; } }

            &__description, &__costs {
                flex-basis: 100%;
                text-align: center;
                font-size: 1em; }
            &__costs {
                max-width: 190px; } }

        &-details {
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
            min-height: 320px;
            border-radius: 25px;
            font-size: 1em;
            max-width: 255px;
            margin-bottom: 20px; }

        &-order {
            background: none;
            padding: 0;
            &__top {
                padding: 20px 15px;
                font-size: 1em;
                line-height: 1.5em; }

            &__itog {
                background: #F6F8FB;
                display: flex;
                flex-flow: column;
                padding: 20px 10px 25px 15px;
                > * {
                    width: 100%; }

                .btn {
                    height: 38px !important;
                    line-height: 35px;
                    border-radius: 24px; } }

            &__itogovaya {
                margin-bottom: 15px;
                p:first-child {
                    font-size: 1.125em;
                    font-weight: bold; } }

            &__description {
                font-size: 0.8em;
                color: black;
                font-weight: 400;
                margin-top: 10px; }

            &__changeDate {
                font-size: 14px;
                color: red; }

            &__desc {
                font-weight: 500; }

            &__weight {
                font-size: 0.88em; }

            .form__field_input {
                height: 38px;
                width: 100%;
                margin-top: 15px;
                &::placeholder {
                    color: #3D3D3D; } } }

        &-bottom {
            &_desc {
                line-height: 1.1em;
                margin-bottom: 10px; } }

        &-navigation {
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 780px;
            margin-bottom: 20px;
            &__menu {
                display: flex;
                &__item {
                    display: flex;
                    &:not(:last-child) {
                        margin-right: 18px; }
                    &:hover {
                        text-decoration: underline;
                        color: #1D3A65;
                        cursor: pointer; }
                    img {
                        margin-right: 7px; } } } } } }


.cart-page-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative; }

.cart-page-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 200;
    background-color: white;
    background: white;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding: 60px 80px 40px;
    align-items: center;
    box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.25);
    border-radius: 35px;
    height: 383px;
    width: 525px;
    > * {
        width: 100%; }

    h2 {
        font-size: 2.88em;
        font-weight: 900;
        text-align: center; }
    img {
        width: 82px; }

    button {
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24); } }

.cart-items_empty {
    button {
        border: none;
        padding: 0 20px;
        text-transform: none; } }


@media screen and (max-width: $mobileWidth) {
    .container_cart {
        min-height: auto;
        margin-bottom: 30px;
        h2 {
          margin-bottom: 20px;
          font-size: 20px; } }

    .cart {
        height: auto;
        &-items {
            height: auto !important;
            max-width: inherit;
            padding: 0px;
            min-height: auto;
            margin-bottom: 17px; }

        &-item {
            display: flex;
            padding-top: 10px;
            flex-direction: column;
            height: auto !important;
            border-bottom: 1px solid #EF6752;
            &__top {
                display: flex;
                width: 100%;
                margin-bottom: 20px; }
            &__bottom {
                display: flex;
                width: 100%;
                justify-content: space-between;
                margin-bottom: 17px; }


            &__img {
                width: 70px;
                height: 50px;
                margin-right: 7px; }


            &__costs {
                flex-basis: auto;
                text-align: start;
                font-size: 16px;
                max-width: 150px !important;
                p {
                    text-align: start; }
                .cart-item__amount {
                    font-size: 14px; } }

            &__counter {
                flex-grow: 1;
                justify-content: flex-start;
                img:first-child {
                    margin-right: 5px; }
                img:last-child {
                    margin-left: 5px; } } }

        &-details {
            box-shadow: none !important;
            display: flex;
            flex-direction: column;
            box-shadow: none;
            min-height: auto;
            max-width: 100% !important;
            border-radius: 25px;
            font-size: 1em;
            width: 100% !important;


            .form__field_input {
                width: 100%; }

            .cart-bottom {
                padding: 0;
                margin-bottom: 25px;
                align-items: flex-start; } }

        &__promo {
            order: 2;
            margin-bottom: 13px; }

        &-bottom {
            order: 1; }


        &-order {
            order: 3;
            flex-direction: column;
            &__top {
                order: 0;
                &__desc {
                    font-weight: 700; }
                margin-bottom: 15px;
                padding: 0 !important; }

            &__itog {
                padding: 20px 0 25px; } }

        &-navigation {
            margin-bottom: 16px;
            flex-direction: column;
            ul {
                width: 100%; }

            &__menu {
                justify-content: space-between;
                align-items: center;
                &__item {
                    &:not(:last-child) {
                        margin-right: 0px; } } } } }
    .cart-page-content {
        flex-direction: column; } }
