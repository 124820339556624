@import "../../styles/sizes";

.navigation-list {
    display: flex;
    margin-bottom: 25px;
    color: #878A95;
    line-height: 1.2em;
    > *:not(:last-child) {
        margin-right: 13px; }

    >:last-child {
        color: #2F323A;
        font-weight: 500;
        overflow: hidden; }
    li {
        display: flex;
        white-space: nowrap;

        &:not(:last-of-type):hover {
            color: #2F323A;
            font-weight: 400; }
        p {
            text-overflow: ellipsis;
            overflow: hidden; }
        a {
            color: inherit;
            display: flex;
            &:hover {
                text-decoration: none; } }
        span {
            margin-left: 13px; } } }

@media screen and (max-width: $mobileWidth) {
    .navigation-list {
        font-size: 14px;
        margin-bottom: 13px; } }
