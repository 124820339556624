@import "../../../styles/sizes";

.catalog-product {
    max-width: 1080px;
    min-height: 830px;
    margin: 0 auto;
    margin-bottom: 60px;

    .image-gallery {
        max-width: 530px;
        flex-basis: 100%;
        position: relative;
        &-image {
            min-height: 200px; } }

    .image-gallery-content {
        .image-gallery-slide .image-gallery-image {
            max-height: 350px;
            object-fit: contain; }

        .image-gallery-thumbnail-image {
            padding: 1px;
            &:hover {
                padding: 0px;
                border: 1px solid black; } } }

    &__content {
        width: 100%; }

    &__top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;
        position: relative; }

    &__description {
        max-width: 550px;
        flex-basis: 100%;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        > * {
            margin-bottom: 20px; } }

    &__weight-description {
        font-family: 'TT Norms';
        font-weight: 300;
        font-style: normal;
        font-size: 10px;
        line-height: 11.8px; }

    &__title {
        margin-bottom: 25px; }

    &__category {
        background: #1D3A65;
        color: white;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        font-size: 0.875em;
        line-height: 1.1em;
        padding: 5px 20px;
        &:hover {
            color: white;
            text-decoration-line: none;
            opacity: 0.7; } }

    &__bold {
        font-weight: bold; }

    &__ordinaryPrice {
        margin-top: 10px;
        font-size: 1.875em;
        span:first-child {
            margin-right: 15px; }
        span:last-child {
            font-size: 0.535em;
            font-weight: normal; } }

    &__price {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        div:last-child {
            img {
                height: 42px;
                cursor: pointer;
                margin-right: 6px; }
            .cart-item {
                &__counter {
                    margin-left: 20px;
                    width: 140px;

                    img:hover {
                        opacity: 0.7; } }

                &__show {
                    min-width: 45px;
                    width: auto;
                    height: 30px;
                    font-size: 20px;
                    line-height: 30px;
                    text-align: center;
                    margin-right: 5px; } } } }

    &__list {
        display: flex;

        &-item {
            display: flex;
            padding: 13px 35px;
            font-size: 1.25em;
            color: #1D3A65;
            font-weight: normal;
            align-items: center;
            transition: all ease 0.2s;
            border-radius: 25px 25px 0px 0px;
            background-color: white;
            cursor: pointer;
            padding-bottom: 30px;
            margin-bottom: -20px;

            img {
                margin-right: 14px; } } }

    &__nalichie {
        position: relative;
        display: flex;
        p {
            margin-right: 5px; }
        &__link {
            display: flex; }
        &__cover {
            position: absolute;
            z-index: 100;
            top: 20px;
            left: -5px;
            box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.15);
            border-radius: 11px;
            width: 270px; } } }


#icon-new {
    top: 0; }

#icon-sale {
    left: 0;
    top: 40px; }

.card-bottom__icon_cart:hover {
    opacity: 0.7; }

@media screen and (min-width: $mobileWidth + 1) {

    .catalog-product {
        &__price {
            div:last-child {
                display: flex; } } }

    .catalog-product__list-item:hover, .catalog-product__list-item.active {
        position: relative;
        background-color: white;
        z-index: 2;
        box-shadow: 0px -13px 13px rgba(0, 0, 0, 0.05); } }

@media screen and (max-width: $mobileWidth) {
    .icons-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center; }
    .product-block__reviews {
        border: none !important; }
    .product-content-block {
        box-shadow: none !important;
        padding: 0 5px !important; }
    .catalog-product {

        &__bottom {
            margin-bottom: 40px; }

        &__top {
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 40px;
            .product-carousel__item {
                img {
                    object-fit: contain;
                    width: 100% !important;
                    height: 215px !important;
                    max-width: inherit !important;
                    max-height: inherit !important; } } }

        &__description {
            width: 100%;
            flex-basis: auto;
            font-size: 14px;
            > * {
                margin-bottom: 17px; } }

        &__title {
            margin-bottom: 16px;
            line-height: 24px;
            font-size: 20px; }

        &__category {
            font-size: 14px;
            line-height: 15px;
            padding: 5px 20px;
            &:hover {
                color: white;
                text-decoration-line: none;
                opacity: 0.7; } }

        &__price {
            margin-top: 10px;
            align-items: flex-end; }

        &__list {
            justify-content: flex-start;
            margin-bottom: 16px;
            &-item {
                flex-wrap: nowrap;
                word-wrap: normal !important;
                padding: 0 14px 6px;
                font-size: 16px;
                align-items: center;
                border-radius: 0;
                background: none; } } }

    .catalog-product__list-item.active {
        border-bottom: 1px solid #1D3A65; } }
