@import "../../../styles/sizes";

.recepies-filter {
    max-width: 1100px;
    margin: 0 auto;
    &__buttons {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 30px; }
    &__button {
        height: 36px;
        padding: 0px 35px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
        border-radius: 38px;
        color: #878A95;
        font-size: 1em;
        line-height: 110%;
        border: none;
        transition: all ease .3s;
        &:not(:last-child) {
            margin-right: 25px; }

        &:hover {
            background-color: white;
            background-color: #1D3A65;
            color: white;
            opacity: 0.8; } } }

.recepies-cards_catalog-category, .catalog_news, .catalog_sales {
    display: flex; }
.placeholder-block {
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    p {
        color: #000;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        text-align: center; } }


.recepies-filter__button.active {
    background-color: #1D3A65;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
    color: white;
    &:hover {
        opacity: 1; } }

@media screen and (max-width: $mobileWidth) {
    .catalog-product {
        // padding: 15px
        .recepies-filter {
            width: 100%;
            &__buttons {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 15px; }
            &__button {
                height: 25px;
                padding: 0 14px;
                box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
                border-radius: 38px;
                background: #FFF;
                color: #878A95;
                font-size: 14px;
                line-height: 110%;
                border: none;
                transition: all ease .3s;
                margin-bottom: 8px; }
            .categories__btn {
                border-radius: 8px;
                font-weight: 500;
                color: #878787;
                padding: 5px 25px;
                margin-bottom: 13px; } }

        .recepies-filter__button.active {
            background-color: #1D3A65;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
            color: white; } } }
