$footerColor: #FFFFFF;
$colorBlue: #1D3A65;

@import "../../styles/sizes";

.section-footer {
    &_top {
        position: relative;
        background: url(../../assets/footerBG1.svg);
        background-size: contain;
        // height: 220px
        &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $colorBlue;
            opacity: 0.97; } }

    &_bottom {
        height: 30px;
        background-color: #DBE6FA;
        p {}

        .container {
            padding-top: 0px;
            padding-bottom: 0px; } } }

.container_footer {
    padding: 15px 90px;
    height: 100%; }

.footer {
    position: relative;
    z-index: 1;
    color: $footerColor;
    height: 100%;

    &__title {

        color: white;
        text-transform: uppercase;
        &_logo {
            width: 300px; } }

    &-block {
        display: flex;
        flex-flow: column;

        &__email {
            font-size: 1.25em;
            line-height: 1.25em;
            margin-bottom: 20px;

            > a {
                color: white;
                &:hover {
                    color: #EF6752 !important; } } }

        &__icons {
            svg:hover path {
                fill: #EF6752; }
            > *:not(:last-child) {
                margin-right: 10px; } }

        &__rekvisits {
            font-size: 1.15em;
            line-height: 1.25em; } }

    a:hover {
        color: white;
        text-decoration-line: none; }

    &_bottom {
        display: flex;
        position: relative;
        align-items: center;
        height: 30px;
        justify-content: space-between; }

    &__copyright {
        color: $colorBlue;
        font-weight: 500;
        font-style: normal;
        font-size: 0.75em;
        line-height: 1.1em; }

    &__icons {
        width: 140px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        left: 50%;
        transform: translate( -50%); } }

.menu_footer .menu__link:hover {
    color: #EF6752; }

@media screen and (min-width: $mobileWidth + 1) {
    .footer {
        display: flex;
        justify-content: space-between;
        font-size: 0.65em;
        line-height: 1.2308em;
        align-items: baseline;

        &__title {
            font-size: 1.25em;
            margin-bottom: 20px; }


        &-block {
            &_contacts {}
            max-width: 270px; } } }

@media screen and (max-width: $mobileWidth) {

    .section-footer {

        &_top {
            padding: 35px 20px;
            height: auto;
            .container_footer {
                padding: 0; } }

        &_bottom {
            height: auto; } }

    .accordion {
        font-size: 14px;
        border-bottom: 1px solid #EF6752;
        margin-bottom: 20px;
        a {
            color: white; } }

    .accordion__title {
        font-weight: 700; }

    .accordion__toggle {
        padding: 16px 0; }

    .accordion__body {
        padding-bottom: 10px;
        line-height: 16px;

        .accordion__item {
            font-weight: 500;
            margin-bottom: 15px; } }

    .footer {
        display: flex;
        flex-direction: column;
        &__title {
            font-size: 11px;
            margin-bottom: 15px; }

        &-block {
            &__email {
                font-size: 14px;
                margin-bottom: 15px; }

            &__rekvisits {
                font-size: 12px;
                line-height: 14px; }

            .logo {
                transform: translateX(-40px); }
            &__icons {
                width: 100%;
                margin-bottom: 20px;
                display: flex; } }

        &_bottom {
            padding: 7px;
            height: auto;
            flex-direction: column-reverse;
            justify-content: center; }

        &__copyright {
            font-size: 12px;
            line-height: 14px; }
        &__icons {
            width: 140px;
            display: flex;
            left: 0;
            transform: none;
            position: static;
            margin-bottom: 14px;
            justify-content: space-between; } } }



