@import "../../../styles/sizes";

.catalog-menu {
    border-radius: 25px;
    width: 223px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    height: auto;
    margin-right: 52px;

    &__item {
        min-height: 50px;
        background-color: #6586B8;
        color: white;
        font-weight: bold;
        font-size: 1em;
        line-height: 1.2em;
        text-transform: uppercase;
        display: flex;
        flex-flow: column;
        box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.6);

        &:first-child {
            border-radius: 15px 15px 0 0;
            a {
                border-radius: 15px 15px 0 0; } }
        &:last-child {
            border-radius: 0 0 15px 15px;
            a {
                border-radius: 0 0 15px 15px; }

            a:hover {
                border-radius: 0px 0px 15px 15px !important; } } }

    &__link {
        color: inherit;
        display: flex;
        width: 100%;
        transition: all ease 0.15s;
        flex-flow: column;

        &:hover {
            background-color: #1D3A65;
            color: inherit;
            text-decoration-line: none;
            opacity: 0.8; }

        p {
            margin: auto 0;
            padding-left: 25px;
            padding-top: 15px;
            padding-bottom: 15px; } } }

.catalog-menu__list {
    display: none; }

.catalog-menu__link.active {
    background-color: #1D3A65;
    color: white;
    text-decoration-line: none;
    &:hover {
        opacity: 1; } }

.catalog-menu__item.active {
    background-color: #1D3A65;
    color: white;
    text-decoration-line: none; }

.catalog-menu__item:last-child {
    // a
    //     border-radius: 0px !important
    ul {
        a:last-child {
            box-shadow: none;
            li {
                border-radius: 0 0 15px 15px; } } } }

.catalog-menu__categories {
    max-height: 0px;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    transition: max-height ease 1.3s;
    &__link {
        box-shadow: inset 0px -1px 0px #6586B8; }

    &__link:hover {
        text-decoration: none; }

    &__item {
        background-color: #fff;
        color: #1D3A65;
        padding: 13px 10px 13px 25px;
        font-size: 1em;
        line-height: 1.1em;
        text-transform: none;
        font-weight: 500;
        box-shadow: inset 0px -1px 0px #6586B8;

        &:hover {
            background: rgba(179, 208, 250, 0.801);
            box-shadow: inset 0px -1px 0px #6586B8;
            color: #1D3A65; }

        &_sublink {
            padding-left: 30px;
            font-weight: 400;
            font-size: 0.8;
            line-height: 1em;
            &:hover {
                background: rgba(248, 248, 248, 0.801);
                box-shadow: inset 0px -1px 0px #6586B8;
                color: #1D3A65; } } } }

.catalog-menu__categories__link.active {
    .catalog-menu__categories__item {
        background: rgba(179, 208, 250, 0.801);
        box-shadow: inset 0px -1px 0px #6586B8;
        color: #1D3A65; } }

.catalog-menu__categories__link_sublink.active {
        .catalog-menu__categories__item {
            background: rgba(248, 248, 248, 0.801);
            box-shadow: inset 0px -1px 0px #6586B8;
            color: #1D3A65; } }


.catalog-menu__link.active + .catalog-menu__categories {
    max-height: 2000px;
    overflow-y: scroll; }

.catalog-content {
    .card-top__img {
        background-color: white;
        width: 254px;
        height: 176px;
        object-fit: cover; } }

.menu_catalog__link_head {
    font-size: 18px !important;
    line-height: 18px !important;
    font-weight: bold !important;
    text-decoration-line: none !important;
    color: #2F323A !important;
    margin-bottom: 8px;
    // min-height: 36px
    // text-align: center
    &:hover {
        color: #EF6752 !important;
        padding: 0px;
        z-index: 1 !important;
        border-radius: 0px !important;
        box-shadow: none !important; } }

@media screen and (max-width: $mobileWidth) {

    .catalog-menu__item:last-child {
        a {
            border-radius: initial !important; }
        ul {
            a:last-child {
                box-shadow: initial;
                li {
                    border-radius: initial; } } } } }
