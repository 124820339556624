.container_error {
    padding-top: 0px; }
.section_error {
    width: 100%;
    height: 660px;

    .container_error {
        display: flex; }

    .error-text {
        margin: auto;
        color: #EF6752;
        display: flex;
        flex-direction: column; }

    .error-title {
        font-size: 144px; }
    .error-desc {
        font-size: 1.5em;
        line-height: 1.1em;
        font-weight: bold; }

    &_404 {
        background: url(../../assets/error404.svg) center no-repeat;
        background-position: bottom;
        background-size: contain; }

    &_502 {
        background: url(../../assets/error502.svg) center no-repeat;
        background-position: bottom;
        background-size: contain; }

    .btn {
        margin: 0 auto;
        background: #EF6752;
        border-radius: 24px;
        color: white;
        margin-top: 10px;
        width: 100%;
        max-width: 276px;
        font-size: 16px;
        line-height: 32px;
        font-weight: bold;
        text-transform: uppercase;
        height: 46px !important;
        &:hover {
            background-color: white;
            border: 1px solid #EF6752;
            color: #EF6752; } } }

