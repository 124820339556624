.container_accept {
    max-width: 1100px;
    padding: 0px;
    padding-bottom: 400px; }

.page-orderAccept {
    h2 {
        margin-bottom: 20px; }
    ul {
        display: flex;
        justify-content: space-between;
        max-width: 800px;
        width: 100%;
        margin-bottom: 30px;
        li {
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            color: #8DD76E;
            img {
                margin-right: 10px; } } }

    p {
        font-size: 18px;
        line-height: 105.9%;
        span {
            font-weight: bold; } } }
