.container_search {
    max-width: 1100px;
    padding: 0;
    width: 100%;
    height: auto;
    min-height: 500px;
    padding-bottom: 40px;
    margin: 0 auto; }

.page-search {
    h2 {
        margin-bottom: 40px; } }

.page-search__empty {
    margin-top: 77px;
    margin-bottom: 50px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    img {
        margin-bottom: 73px; }
    .bold {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #000; }
    .light {
        font-size: 14px;
        line-height: 1.25em;
        margin-bottom: 15px; }

    .btn {
        text-transform: none;
        width: 228px;
        margin-bottom: 0;
        line-height: 40px;
        &:hover {
            border: 1px solid #EF6752;
            background: white;
            color: #EF6752; } } }
