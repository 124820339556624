@import "../../styles/sizes";

.container_shops {
    max-width: 1180px;
    margin: 0 auto;
    padding-top: 0;
    h2 {
        margin-bottom: 25px; } }

.shops-page .container_map {
    padding: 0;
    margin-bottom: 40px; }

@media screen and (max-width: $mobileWidth) {

    .container_shops {
        h2 {
            margin-bottom: 12px;
            font-size: 22px; } }

    .shops-page .container_map {
        margin-bottom: 20px; } }
