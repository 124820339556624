@import "../../styles/sizes";

.container_delivery {
    max-width: 1180px;
    padding-top: 0; }

.delivery {
    display: flex;
    flex-flow: column;
    font-size: 1em;
    margin: 0 auto;

    h3 {
        margin-bottom: 23px; }

    > * {
        margin-bottom: 45px;
        display: flex;
        justify-content: space-between; }

    &-del {
        display: flex;
        &__desc {
            max-width: 392px;
            flex-basis: 40%; }
        &__map {
            max-height: 430px; } }

    &-vivoz, &-payment {
        flex-flow: column; }

    &-block {
        margin-bottom: 25px;

        &__title {
            font-size: 1em;
            line-height: 1.2em;
            magin-bottom: 5px;
            color: #000; } } }

@media screen and (max-width: $mobileWidth) {

    .delivery {
        &__title {
            font-size: 22px;
            margin-bottom: 9px !important; }

        h3 {
            margin-bottom: 17px;
            font-size: 24px; }

        > * {
            margin-bottom: 0px; }

        &-del {
            display: flex;
            flex-flow: column;
            &__map {
                max-height: auto;
                width: 100%;
                margin: 20px 0;
                border-radius: 15px; } }

        &-payment {
            &__desc {
                margin-bottom: 45px;
                font-weight: 700; } } } }
