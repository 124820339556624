$colorTitles: #1D3A65;
$colorMiddleDescriprion: #878A95;

@import "../../styles/sizes";

.block {
    display: flex;
    flex-flow: column;
    max-width: 1100px;
    margin: 0 auto;
    min-height: 423px;
    margin-bottom: 40px;
    &__title {
        margin-bottom: 30px; }

    .product-content-block {
        box-shadow: none;
        padding: 0;

        .block_slider_recepies {
            width: 100%; } }

    &_slider_recepies {
        // flex-flow: column !important
 } }        // margin: 0 -100px !important

.cards {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    max-width: 1100px; }

.card {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    flex-basis: 250/1100 * 100%;
    border: none;
    > * {
        width: 100%; }

    &__name {
        color: $colorTitles; }

    &__date {
        color: $colorMiddleDescriprion; }

    &__title {
        height: 52px;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;


        &_recepies {
            margin-bottom: 10px; }

        &_advantages {
            font-size: 1.5em;
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 12px; }

        &_reviews {
            margin-bottom: 15px; } }


    &__description {
        text-overflow: ellipsis;
        height: 60px;
        overflow: hidden;
        margin-bottom: 10px;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        &_advantages {
            text-align: center; }
        &_more {
            color: #EF6752;
            &:hover {
                text-decoration-line: underline; } } }

    // -----------GOODS----------

    &-bottom__costs {
        color: $colorTitles;
        font-size: 1.375em;
        font-weight: bold; }

    &-top {
        margin-bottom: 25px;
        position: relative;
        text-align: center;
        &__icons {
            position: absolute;
            bottom: 0;
            left: 10px; }
        &__icon {
            position: absolute;
            bottom: 0;
            &:first-child {
                left: 0;
                z-index: 2; }
            &:nth-of-type(2) {
                left: 20px;
                z-index: 1; } } }

    &-middle {
        margin-bottom: 15px; }

    &-bottom {
        display: flex;
        justify-content: space-between;
        &__icons {
            display: flex;
            &>:first-child {
                margin-right: 8px; } }
        &__icon:hover {
            cursor: pointer; } }



    &__description, &-bottom__costs_throw, &-bottom__weight {
        color: $colorMiddleDescriprion; }

    &-bottom__costs_throw {
        font-size: 0.73em;
        margin-left: 10px;
        text-decoration-line: line-through; }

    // -----------RECEPIES-----------
    &_recepies {
        align-items: flex-start; }

    &__img {
        border-radius: 8px;
        max-width: 100%;
        height: auto;
        margin-bottom: 15px; }

    // -----------REVIEWS-----------

    &__score {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px; }

    &__stars {
        display: flex;
        > *:not(:last-child) {
            margin-right: 4px; } }

    &__title_reviews, &__name {
        width: 100%;
        text-align: left; }

    &__name {
        margin-top: 10px; }

    &_advantages {
        flex-basis: 30%; } }

.advantages__title {
    margin-bottom: 45px; }

.slick-slide .card-top__img_item {
    display: none; }

.cards_recommend .slick-slide[data-index="0"] .card-top__img_item {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-50%);
    display: block; }

.card:hover {
    background: #F6F8FB; }

@media screen and (max-width: $mobileWidth) {
    .card__title {
        height: auto;
        font-size: 16px; }

    .block {
        margin-bottom: 60px;
        min-height: auto;

        &__title {
            margin-bottom: 20px;
            font-size: 22px; } }

    .card-bottom__costs {
        font-size: 14px; }
    .cards {
        width: 100%;
        .card_recepies {
            margin-bottom: 0;
            .card {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-bottom: 0;
                &__img {
                    width: 100% !important;
                    max-width: initial;
                    height: 120px; }
                &-top {
                    margin-bottom: 20px; }
                &__title {
                    height: auto; } } } }

    .card {
        .card-top {
            margin-bottom: 20px; }



        &__title {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 5px;
            margin-top: 20px;

            &__description {
                color: #878A95;
                font-size: 14px;
                margin-bottom: 22px; } }

        &-bottom__costs {
            font-size: 16px; }

        &-bottom {
            align-items: flex-start;
            img {
                max-height: 28px !important;
                max-width: 28px !important; } }

        &-bottom__price {
            margin-top: 35px;
            display: flex;
            align-items: flex-end; }

        &-bottom__weight {
            font-size: 14px;

            &__description {
                color: #878A95;
                font-size: 14px;
                margin-bottom: 22px; } }

        &-bottom__costs_throw {
            font-size: 12px;
            margin-left: 0px; } } }

