$formPadding: 70px 75px;
$colorRed: #EF6752;
$colorLinks: #1D3A65;

@import "../../styles/sizes";

.form {
    &-close {
        position: absolute;
        top: 17px;
        right: 17px;
        transition: all ease 0.4s;
        &:hover {
            cursor: pointer;
            transform: rotate(90deg); } }

    &__title {
        text-align: center;
        font-size: 2.75em;
        color: $colorRed;
        font-weight: 900; }

    &__error-message {
        text-align: center;
        width: 100%;
        p {
            color: red !important; } }

    &-fields {
        display: flex;
        flex-flow: column; }

    &__field {
        height: 45px;
        border: 2px solid $colorRed;
        border-radius: 26px;
        padding: 0;
        padding-left: 18px;
        &:first-child {
            margin-bottom: 17px; }

        &[type="password"]:focus {
            font-size: 20px;
            line-height: 20px; } }

    .validate-message {
        text-align: center;
        color: red;
        font-weight: 500;
        margin-bottom: 10px; }

    &-bottom {
        display: flex;
        flex-flow: column; }

    &-btn {
        height: 45px;
        padding: 0;
        border-radius: 25px;
        background-color: $colorRed;
        color: white;
        text-transform: uppercase;
        margin-bottom: 10px;
        &_main {
            background-color: #C4C4C4;
            border: none;
            height: 45px;
            padding: 0;
            border-radius: 25px;
            color: white;
            text-transform: uppercase;
            margin-bottom: 10px; }

        &_main.active {
            background-color: $colorRed; } }



    &__description {
        display: flex;
        justify-content: center;
        color: #878A95;
        &_first {
            margin-top: 5px; }
        p {
            text-align: center; }

        &_link:hover {
            text-decoration-line: none; } }

    &__politica {
        display: flex;
        padding-left: 10px;
        padding-right: 5px;
        align-items: baseline;
        input[type="checkbox"] {
            margin-right: 7px;
            box-sizing: content-box; }
        label {
            cursor: pointer; } }
    a {
        color: $colorLinks;
        text-decoration-line: underline;
        text-align: center; } }

.form.active {
    display: flex; }

.form {
    &_recovery {
        .form__description_first {
            color: #878A95; } } }

.form__description__link {
    cursor: pointer;
    color: #1D3A65;
    text-decoration-line: underline;
    &:hover {
        text-decoration-line: none; } }

.form_registration {
    height: 580px !important;
    label {
        cursor: pointer; }
    .form__title {
        margin-bottom: 20px; }
    .form-fields {
        height: 450px;
        justify-content: space-between;
        margin-bottom: 20px; }
    .form__field:first-child {
        margin-bottom: 0px; } }

.form_reset {
    .form__field {
        margin-bottom: 17px;
        &:first-of-type {
            margin-top: 10px; } } }


.form_review {
    height: 508px;
    padding: 30px 65px 20px;
    h2 {
        font-size: 32px;
        margin-right: auto;
        font-weight: bold; }
    .form__field_textarea {
        height: 183px;
        padding: 10px 10px 18px 10px; }
    input[type="file"] {
        display: none; } }

.label-file {
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
        margin-right: 5px; }
    p {
        color: #1D3A65;
        text-decoration-line: underline; }
    &:hover {
        p {
            text-decoration-line: none; } } }

.form__field.active {
    color: red; }

.form_modal-agree {
    height: 452px;
    position: relative;
    z-index: 1000 !important;
    h2 {
        font-weight: 900;
        font-size: 44px;
        line-height: 1.1em;
        text-align: center; }

    p {
        color: #878A95;
        cursor: pointer; }
    label {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        input {
            margin-right: 5px; } }

    &_link:hover {
        cursor: pointer;
        transition: all .2s ease;
        text-decoration-line: none; } }

.form_signin {
    z-index: 1000 !important; }

@media screen and (min-width: $mobileWidth + 1) {
    .form {
        display: none;
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 102;
        background-color: #fff;
        transform: translate(-50%, -50%);
        width: 525px;
        flex-flow: column;
        justify-content: space-between;
        box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.25);
        border-radius: 35px;
        padding: $formPadding;
        height: 494px; } }

@media screen and (max-width: $mobileWidth) {
    $formPadding: 47px 15px 53px;

    .form_modal-agree {
        width: 100%;
        height: auto;
        max-width: 345px;
        h2 {
            font-size: 32px;
            margin-bottom: 20px; }
        .form__field_input {
            margin-bottom: 5px;
            width: 100%; }
        label {
            margin-bottom: 25px; }
        .btn {
            width: 100%; }
        &_link {
            display: block;
            width: 100%;
            text-align: center; } }

    .form {
        display: block;
        background-color: #fff;
        position: relative;
        width: 100%;
        flex-flow: column;
        justify-content: space-between;
        box-shadow: 0px 0px 38px rgba(0, 0, 0, 0.25);
        border-radius: 35px;
        padding: $formPadding;

        .link {
            color: $colorLinks;
            text-decoration-line: underline;
            text-align: center; }

        &__title {
            font-size: 28px;
            color: $colorRed;
            margin-bottom: 18px; }

        &__field {
            padding: 0 0 0 18px; }

        &-btn {
            font-weight: 700; }

        &__description {
            &_first {
                margin-bottom: 21px; }
            p {
                font-size: 14px; } }

        &__politica {
            margin-top: 7px;
            font-size: 12px;
            align-items: flex-start;
            p {
                line-height: 17px; }
            input[type="checkbox"] {
                width: 16px;
                height: 16px;
                flex: none; } } } }
