@import "../../../styles/sizes";

.vacancies {
    &-items {
        flex-basis: 70%;
        max-width: 730px; }

    &-item {
        display: flex;
        flex-flow: column;
        width: 100%;
        padding: 25px 30px;
        line-height: 1.06em;
        font-weight: 500;

        &__top {
            margin-bottom: 5px; }

        &__bottom {
            display: flex; }

        &__title {
            color: #1D3A65;
            font-size: 1.5em; }

        &__address {
            font-size: 1em;
            color: #878A95; }

        &__cost {
            font-size: 1.5em;
            color: #000; }

        &__phone {
            font-size: 1em;
            line-height: 1.1em;
            text-align: right;
            span {
                font-weight: 400; } } } }

@media screen and (min-width: $mobileWidth + 1) {
    .vacancies {
        &-items {
            min-height: 775px; }
        &-item {
            justify-content: space-between;
            height: 140px;
            margin-bottom: 15px;
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.15);
            border-radius: 20px;

            &__bottom {
                justify-content: space-between;
                align-items: center; } } } }

@media screen and (max-width: $mobileWidth) {

    .vacancies {
        &-items {
            flex-basis: auto;
            max-width: auto;
            width: 100%;
            margin-bottom: 35px; }

        &-item {
            border-bottom: 1px solid #EF6752;
            padding: 20px 0;
            line-height: 15px;

            &__bottom {
                flex-direction: column; }

            &__title {
                font-size: 18px;
                margin-bottom: 5px; }

            &__address, &__cost {
                margin-bottom: 14px; }

            &__phone {
                font-size: 14px;
                line-height: 14px;
                text-align: auto;
                .phone {
                    font-weight: 400;
                    margin-top: 5px; } } } } }
