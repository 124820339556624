.catalog-main {
    max-width: 825px;
    width: 100%;
    .catalog-product {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        width: 100%;
        max-width: none;
        margin-bottom: 60px; }
    &__load {
        padding: 10px 20px;
        height: 35px;
        border-radius: 20px;
        font-size: 0.8em;
        line-height: 1em;
        margin: 0 auto;
        margin-top: 20px; } }
