@import "../../styles/sizes";

.container_carousel {
    max-width: 1100px;
    height: 500px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    margin-bottom: 40px;
    .slick-slider .slick-slide {
        border: none;
        padding: 0px !important; } }

.carousel__item {
    &_1::before {
        background: url(../../assets/SliderMain/1.svg); }
    &_2::before {
        background: url(../../assets/SliderMain/2.JPG); }
    &_3::before {
        background: url(../../assets/SliderMain/3.JPG); }
    &_4::before {
        background: url(../../assets/SliderMain/4.JPG); }
    &_5::before {
        background: url(../../assets/SliderMain/5.JPG); }
    &_6::before {
        background: url(../../assets/SliderMain/6.JPG); }
    &_7::before {
        background: url(../../assets/SliderMain/7.JPG); }
    &_8::before {
        background: url(../../assets/SliderMain/8.JPG); } }



.carousel {
    height: 100%;
    .slick-list {
        border-radius: 24px; }
    .slick-list, .slick-track, .slick-slide {
        height: 100%; }

    .slick-slide > div {
        height: 100%; }

    .slide-background-img {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 24px; }

    &__item {
        position: relative;
        width: 100%;
        height: 100% !important;
        left: 0;
        top: 0;
        bottom: 0;
        outline: none;
        &:active, &:focus {
            outline: none; }

        h1 {
            position: absolute;
            top: 40px;
            left: 50px;
            max-width: 1000px;
            font-size: 40px;
            color: white;
            text-shadow: 7px 7px 9px rgba(150, 150, 150, 1); }

        img {
            position: absolute;
            bottom: 80px;
            right: 90px; }

        &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 24px;
            background-size: cover;
            background-position: center center no-repeat; } }
    .slick-next {
        right: 0px;
        display: flex;
        align-items: center;
        border-radius: 0px 24px 24px 0px;
        height: 500px;
        width: 95px;
        svg {
            position: absolute;
            right: 10px; }
        svg path {
            transition: all ease .5s; }
        &:hover {
            svg path {
                fill: white; }
            &::before {
                opacity: 1;
                transition: opacity ease .5s; } }
        &::before {
            content: '';
            opacity: 0;
            height: 100%;
            width: 100%;
            border-radius: 0px 24px 24px 0px;
            background: linear-gradient(-90deg, rgba(0, 0, 0, 0.27) 0%, rgba(255, 255, 255, 0) 100%); } }


    .slick-prev {
        left: 0px;
        display: flex;
        align-items: center;
        height: 500px;
        border-radius: 24px 0px 0px 24px;
        width: 95px;
        &:hover {
            svg path {
                fill: white; }
            &::before {
                opacity: 1;
                transition: opacity ease .5s; } }
        svg {
            position: absolute;
            left: 10px; }

        svg path {
            transition: all ease .4s; }
        &::before {
            content: '';
            opacity: 0;
            height: 100%;
            width: 100%;
            border-radius: 24px 0px 0px 24px;
            background: linear-gradient(90deg, rgba(0, 0, 0, 0.27) 0%, rgba(255, 255, 255, 0) 100%); } }

    .slick-dots {
        position: absolute;
        bottom: 20px;
        li {
            margin: 0; }
        .slick-active {
            button:before {
                color: white; } }
        button:before {
            color: white;
            font-size: 10px; } } }

.slider-btn {
    position: absolute;
    text-transform: none;
    height: 40px;
    padding: 0 25px;
    bottom: 40px;
    line-height: 40px;
    left: 100px;
    border-radius: 12px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }


@media screen and (max-width: $mobileWidth) {
    .container_carousel {
        min-width: 320px;
        height: 160px;
        width: 100%;
        margin-bottom: 20px;
        margin-top: 5px;
        padding: 0 5px;

        .slide-background-img {
            object-fit: cover;
            height: 404px;
            max-height: 100%;
            max-width: 100%; } }

    .slider-btn {
        height: auto;
        line-height: 14px;
        padding: 7px 10px;
        bottom: 20px;
        font-size: 14px;
        left: 18px;
        line-height: 25px; }

    .carousel {
        &__item {
            height: auto;

            h1 {
                top: 23px;
                left: 18px;
                font-size: 14px;
                max-width: 320px; }

            img {
                bottom: 30px;
                right: 25px;
                max-height: 90px;
                max-width: 90px; }

            .slick-dots {
                bottom: 10px; } } } }
