@import "~react-image-gallery/styles/css/image-gallery.css";
@import "../../../styles/sizes";

$colorRed: #EF6752;
$colorBlue: #1D3A65;

.shop-card {
    &__title_red {
        color: $colorRed;
        font-weight: 700; }
    &__title_blue {
        color: $colorBlue;
        font-weight: 700;
        font-size: 2.25em;
        margin-bottom: 15px; }
    h4 {
        color: #000;
        font-weight: 700;
        font-size: 1em;
        margin-bottom: 5px; }
    &__top {
        display: flex;
        align-items: flex-start; }
    &__gallary {
        max-width: 645px;
        width: 100%;
        .image-gallery-image {
            max-height: 425px !important; }

        .image-gallery-thumbnail-image {
            padding: 1px;
            &:hover {
                border: 1px solid black;
                padding: 0px; } } }

    &__description {
        display: flex;
        flex-flow: column;
        align-items: flex-start; }
    &__btn {
        background-color: $colorRed;
        border-radius: 38px;
        color: white;
        border: none;
        font-weight: 700;
        &:hover {
            border: 1px solid $colorRed;
            color: $colorRed;
            background: white; } }

    &__bottom {
        margin-top: 60px;
        display: flex;
        justify-content: space-between; }

    &__map {
        width: 645px; }

    &__find {
        width: 395px;
        video {
            objectPosition: bottom;
            object-fit: cover; } } }

@media screen and (min-width: $mobileWidth + 1) {
    .shop-card {
        &__description {
            justify-content: space-between;
            min-width: 300px;
            min-height: 420px;
            margin-left: 40px; }
        &__btn {
            width: 216px;
            height: 34px; } } }

@media screen and (max-width: $mobileWidth) {
     .shop-card {
        h4 {
            margin-top: 15px; }

        &__top {
            flex-direction: column; }

        &__gallary {
            width: 100%;
            flex-basis: auto; }

        &__btn {
            padding: 8px 23px 8px 12px;
            display: flex;
            align-items: center;
            margin-top: 15px;
            img {
                margin-right: 5px; } }

        &__bottom {
            margin-top: 35px;
            flex-direction: column; }

        &__map {
            width: 100%;
            margin-bottom: 25px; }

        &__find {
            width: 100%;
            flex: 1;
            margin-bottom: 20px;
            video {
                width: 100%;
                border-radius: 10px; } } } }
