@import "../../styles/sizes";

.card__img_advantages {
    max-width: 158px; }

.advantages {
    &-company {
        margin-top: 120px;
        display: flex;
        flex-flow: column;
        align-items: center;

        &__title {
            font-weight: normal;
            color: #F06853;
            text-align: center;
            margin-bottom: 35px; }

        &__description {
            text-align: justify;
            color: #1D3A65; } } }

@media screen and ( max-width: $mobileWidth) {
    .advantages {
        &__title {
            font-size: 22px;
            margin-bottom: 20px; }
        .advantages-company {
            margin-top: 40px;
            &__title {
                font-size: 16px;
                margin-bottom: 30px; }
            &__description {
                font-size: 12px;
                line-height: 13px;
                margin-bottom: 30px; } }

        .cards_advantages {
            display: flex;
            flex-direction: column; }

        .card {
            display: flex;
            flex-direction: row;
            padding: 0;
            justify-content: flex-start;
            margin-bottom: 28px;
            & > div {
                display: flex;
                flex-direction: column; }
            &__img {
                max-height: 85px;
                max-width: 85px;
                margin-right: 24px; }
            &__title {
                font-size: 18px;
                margin-top: 0;
                height: auto;
                text-align: start;
                margin-bottom: 10px; }
            &__description {
                text-align: start;
                font-size: 14px;
                line-height: 15px; } } }

    &-company {
        margin-top: 12px;

        &__title {
            font-size: 16px; }

        &__description {
            text-align: start;
            font-size: 12px;
            margin-bottom: 29px; } } }
