@import "sizes";

$paddingContainerLaptop: 30px 40px;
$colorHeaders: #EF6752;
$colorTitles: #1D3A65;

body {
    font-family: 'TT Norms', sans-serif;
    font-size: 16px;
    line-height: 1.1875em;
    color: #000000;
    background: #FFFFFF; }

.container {
    max-width: 1440px;
    padding: $paddingContainerLaptop;
    margin: auto;
    &_middle {
        max-width: 1180px; } }

.menu {
    font-size: 1.25em;
    line-height: 1.25em; }

.link {
    &:hover {
        cursor: pointer;
        opacity: 0.8; } }

h1 {
    color: $colorHeaders;
    text-transform: uppercase;
    font-size: 4em;
    text-align: left;
    font-weight: bold; }

h2 {
    color: $colorHeaders;
    font-size: 2em;
    font-weight: bold;
    line-height: 1.06em; }

h3 {
    color: $colorTitles;
    font-size: 2.25em;
    font-weight: bold; }

h4 {
    color: $colorTitles;
    font-size: 1.375em;
    font-weight: bold; }

label {
    cursor: pointer; }

.btn_close {
    transition: all ease 0.2s;
    &:hover {
        cursor: pointer;
        transform: rotate(90deg); } }

.card a:hover {
    text-decoration-line: none; }
.block .slick-slide {
    // padding: 5px 0 20px !important
    box-sizing: border-box; }
.block .slick-slide:hover {
    border: 3px solid #EDE8E8;
    border-radius: 13px; }


.card:hover {
    background-color: white !important; }
.block {
    .slick-slide:first-child {
        padding-left: 10px !important;
        padding-right: 10px !important; }
    .card {
        width: 249px !important;
        padding-top: 10px;
        padding-bottom: 10px;
        &-top__img {
            max-width: 254px; } } }

.block .card:hover {}

.catalog-product {}
.recepies-cards {
    .card {
        width: 275px !important; }
    .card:hover {
        border: 3px solid #EDE8E8; } }

.slick-slide:not(:first-child) {
    padding: 3px 10px !important; }


.card_recepies:hover {
    text-decoration: none; }

.block_slider {
    min-width: 300px;
    margin-bottom: 60px; }

.block .slick-slide, .recipe .slick-slide {
    padding-top: 3px !important;
    padding-bottom: 3px !important; }

.recipe .card__img:hover {
    background: white !important; }

.block .slick-slide:hover, .recipe .slick-slide:hover {
    background: white !important;
    padding: 0 7px !important;
    .card__title {
        opacity: 0.8; }
    // .card__description_more
 }    //     text-decoration-line: underline

.form-btn:hover {
    color: #EF6752 !important;
    border: 1px solid #EF6752 !important;
    background: white !important; }

@media screen and (max-width: $mobileWidth) {
    body {
        height: 100vh;
        overflow: auto; }
    .header-section {
        height: 57px; }
    .header-container {
        z-index: 10000;
        top: 0;
        position: fixed;
        background: #FFFFFF; }
    .container {
        min-width: 320px;
        padding: 5px 15px 0; }
    .block .slick-slide:hover {
        border: none;
        border-radius: 0; }

    .block .card {
        padding: 0;
        width: auto !important; }
    .card {
        padding: 10px 15px;
        position: relative;
        &-images__icons {
            position: absolute;
            display: flex;
            left: 0;
            bottom: 0; }
        &-top__img {
            max-width: 140px !important; } }
    .recepies-cards {
        .card:hover {
            border: none; } }
    .block_slider {
        padding: 0 15px;
        margin-bottom: 0; }

    .catalog-content .card-top__img {
        max-width: 100% !important;
        object-fit: cover; }

    .block .slick-slide:hover, .recipe .slick-slide:hover {
        background: white;
        padding: 3px 5px !important; }


    .block .slick-slide {
        padding: 3px 5px !important; }

    .block .card a {
        height: 210px; }

    .block {
        .slick-slide:first-child {
            padding-left: 5px !important;
            padding-right: 5px !important; } } }
